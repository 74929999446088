import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  TabStopType,
} from "docx";
import { toTitleCase } from "../helpers";
import { getTemplateFunctions, splitTextToLines } from "../templates/utils";
import { strongText, formatText } from "./viDocxService";

// Indemnity agreement text
const indemnityText = [
  "To indemnify you, your servants and agents and to hold all of you harmless in respect of any liability, loss, damage or expense of whatsoever nature which you may sustain by reason of making the requested amendment(s).",
  "In the event of any proceedings being commenced against you or any of your servants or agents in connection with making the requested amendment(s) as aforesaid, to provide you or them on demand with sufficient funds to defend the same.",
  "If, in connection with the delivery of the Cargo as aforesaid, the Vessel, or any other vessel or property in the same or associated ownership, management or control, or any vessel or property in your ownership, management or control, should be arrested or detained or should the arrest or detention thereof be threatened, or should there be any interference in the use or trading of the Vessel or such other vessels or property (whether by virtue of a caveat being entered on the Vessel's or such other vessel's registry or otherwise howsoever):",
  "If the place at which we have asked you to make delivery is a bulk liquid, dry bulk cargo or gas terminal or other facility, or another vessel, lighter or barge, then discharge or delivery to such terminal, facility, vessel, lighter or barge shall be deemed to be delivery to the party to whom we have requested you to make such delivery.",
  "As soon as all original bill of lading for the above Cargo shall have come into our possession, to deliver the same to you, whereupon (always provided that the said bill of lading have been properly tendered by the party to whom the Cargo was actually delivered) our liability hereunder shall cease.",
  "The liability of each and every person under this indemnity shall be joint and several and shall not be conditional upon your proceeding first against any person, whether or not such person is party to or liable under this indemnity.",
  "This indemnity shall be governed by and construed in accordance with English law and each and every person liable under this indemnity submits to the exclusive jurisdiction of the High Court of Justice of England.",
];

// Sublist to appear under the third item
const indemnitySublist = [
  "to provide on demand such bail or other security as may be required to prevent such arrest or detention or to secure the release of the Vessel or such other vessel or property or to remove such interference;",
  "if you have already provided security, to provide on demand equivalent substitute or counter security, whether or not you have made any prior demand upon us and whether or not such security exceeds the value of the Vessel or such other vessel, and",
  "to indemnify you in respect of any liability, loss, damage or expense caused by such arrest or detention or threatened arrest or detention or such interference, whether or not such arrest or detention or threatened arrest or detention or such interference may be justified.",
];

// Create the list of paragraphs with sublist
const indemnityList = indemnityText
  .map((text, index) => {
    // If it's the third paragraph (index 2), add the sublist after it
    if (index === 2) {
      // Create the main list item
      const mainParagraph = new Paragraph({
        children: [new TextRun(text)],
        spacing: {
          after: 100, // 100 twips after the paragraph
        },
        numbering: {
          reference: "indemnity-numbering",
          level: 0, // Main list level
        },
      });

      // Create the sublist items (a, b, c)
      const sublistParagraphs = indemnitySublist.map((subItem) => {
        return new Paragraph({
          children: [new TextRun(subItem)],
          spacing: {
            after: 100, // 100 twips after the paragraph
          },
          numbering: {
            reference: "indemnity-numbering",
            level: 1, // Sublist level
          },
        });
      });

      // Return both the main paragraph and the sublist
      return [mainParagraph, ...sublistParagraphs];
    } else {
      // Return a normal main list item for other paragraphs
      return new Paragraph({
        children: [new TextRun(text)],
        spacing: {
          after: 100, // 100 twips after the paragraph
        },
        numbering: {
          reference: "indemnity-numbering",
          level: 0,
        },
      });
    }
  })
  .flat(); // Flatten the array to avoid nested arrays

// Function to convert an image as Base64
export const imageToBase64 = async (imagePath) => {
  if (imagePath === undefined) {
    imagePath = "/assets/empty.png";
  }

  const response = await fetch(imagePath);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const handleExportToWordBlobLOI = async (formData) => {
  const {
    currentDate,
    vessel,
    ownersManagers,
    portLoading,
    portDischarge,
    cargo,
    billOfLadingIdentificationNumber,
    billOfLadingPlaceAndDateOfIssue,
    consignee,
    deliveryParty,
    shipper,
    requestorParty,
    requestorName,
    requestorDesignation,
    deliveryPlace,
    address,
    letterhead,
    loiTemplate,
    missingValue,
  } = formData;

  const templateText = await getTemplateFunctions[loiTemplate.type]({
    portLoading,
    portDischarge,
    cargo,
    shipper,
    consignee,
    requestorParty,
    deliveryParty,
    deliveryPlace,
    requestorName,
    requestorDesignation,
  });

  // Helper function to create a Paragraph
  const createParagraph = (
    textRuns,
    spacingAfter = 100,
    bulletLevel = -1,
    leftIndentation = undefined,
    hangingIndentation = undefined
  ) => {
    let leftIndent = { 0: 720, 1: 720, 2: 0 };
    let hangingIndent = { 0: 360, 1: 360, 2: 0 };

    if (bulletLevel >= 0) {
      leftIndentation = leftIndent[bulletLevel];
      hangingIndentation = hangingIndent[bulletLevel];
    }

    return new Paragraph({
      children: textRuns.flatMap((text) => formatText(text)),
      numbering:
        bulletLevel >= 0
          ? { reference: "indemnity-numbering", level: bulletLevel }
          : undefined,
      indent:
        leftIndentation !== undefined || hangingIndentation !== undefined
          ? { left: leftIndentation, hanging: hangingIndentation }
          : undefined,
      spacing: { after: spacingAfter },
    });
  };

  // Creates a section of paragraphs
  const createSection = (texts, bulletLevel = -1, listHasSpacing = false) => {
    let result = [];
    for (let i = 0; i < texts.length; i++) {
      const currentText = texts[i];
      const nextText = texts[i + 1];

      // If newline, adds spacing 200 to simulate a new line
      const newLine = nextText && !nextText.trim();
      let spacingAfter = newLine ? 200 : 0;
      if (listHasSpacing) {
        spacingAfter = 200;
      }
      result.push(createParagraph([currentText], spacingAfter, bulletLevel));
    }
    return result;
  };

  const getCargoDescriptionParagraph = () => {
    if (!loiTemplate) return null;

    switch (loiTemplate.type) {
      case "DEFAULT_LOI_NO_BILL":
        return new Paragraph({
          children: [
            new TextRun({
              text: "The above Cargo was shipped on the above Vessel by ",
            }),
            strongText(`${shipper}`),
            new TextRun({ text: " and consigned to " }),
            strongText(`${consignee}`),
            new TextRun({
              text: " for delivery at the Port of Discharge but the Bill(s) of Lading is (are) not currently available to be presented.",
            }),
          ],
          spacing: {
            after: 100,
          },
        });
      case "DEFAULT_LOI_OTHER_PORT":
        return new Paragraph({
          children: [
            new TextRun({
              text: "The above Cargo was shipped on the above Vessel by ",
            }),
            strongText(`${shipper}`),
            new TextRun({ text: " and consigned to " }),
            strongText(`${consignee}`),
            new TextRun({
              text: " for delivery at the Port of Discharge but we, ",
            }),
            strongText(`${requestorParty}`),
            new TextRun({
              text: ", hereby request you to order the Vessel to proceed to and deliver the said cargo at ",
            }),
            strongText(`${deliveryPlace}`),
            new TextRun({
              text: " against production of at least one original Bill(s) of Lading.",
            }),
          ],
          spacing: {
            after: 0,
          },
        });
      case "DEFAULT_LOI_NO_BILL_OTHER_PORT":
        return new Paragraph({
          children: [
            new TextRun({
              text: "The above Cargo was shipped on the above Vessel by ",
            }),
            strongText(`${shipper}`),
            new TextRun({ text: " and consigned to " }),
            strongText(`${consignee}`),
            new TextRun({
              text: " for delivery at the Port of Discharge but we, ",
            }),
            strongText(`${requestorParty}`),
            new TextRun({
              text: ", hereby request you to order the Vessel to proceed to and deliver the said cargo at ",
            }),
            strongText(`${deliveryPlace}`),
            new TextRun({ text: "." }),
          ],
          spacing: {
            after: 100,
          },
        });
      default:
        return createParagraph([""]);
    }
  };

  const getIndemnityRequestParagraph = () => {
    if (!loiTemplate) return null;

    switch (loiTemplate.type) {
      case "DEFAULT_LOI_NO_BILL":
        return new Paragraph({
          children: [
            new TextRun({ text: "We, " }),
            strongText(`${requestorParty}`),
            new TextRun({ text: ", hereby represent and undertake that " }),
            strongText(`${deliveryParty}`),
            new TextRun({
              text: " is the party lawfully entitled to delivery of the said Cargo and request you to deliver the said Cargo to ",
            }),
            strongText(`${deliveryParty}`),
            new TextRun({
              text: " or to such party as you believe to be or to represent ",
            }),
            strongText(`${deliveryParty}`),
            new TextRun({ text: " or to be acting on behalf of " }),
            strongText(`${deliveryParty}`),
            new TextRun({ text: " at " }),
            strongText(`${deliveryPlace}`),
            new TextRun({
              text: " without production of the original Bill(s) of Lading.",
            }),
          ],
          spacing: {
            after: 100,
          },
        });
      case "DEFAULT_LOI_NO_BILL_OTHER_PORT":
        return new Paragraph({
          children: [
            new TextRun({
              text: "Further, as the Bill(s) of Lading is (are) not currently available to be presented, we also hereby represent and undertake that ",
            }),
            strongText(`${deliveryParty}`),
            new TextRun({
              text: " is the party lawfully entitled to delivery of the said Cargo and request you to deliver the said Cargo to ",
            }),
            strongText(`${deliveryParty}`),
            new TextRun({
              text: " or to such party as you believe to be or to represent ",
            }),
            strongText(`${deliveryParty}`),
            new TextRun({ text: " or to be acting on behalf of " }),
            strongText(`${deliveryParty}`),
            new TextRun({ text: " at " }),
            strongText(`${deliveryPlace}`),
            new TextRun({
              text: " without production of the original Bill(s) of Lading.",
            }),
          ],
          spacing: {
            after: 100,
          },
        });
      default:
        return createParagraph([""], 0);
    }
  };

  const getIndemnityList = (indemnityAgreement, indemnitySublist) => {
    return indemnityAgreement
      .map((text, index) => {
        // If it's the third paragraph (index 2), add the sublist after it
        if (index === 2) {
          // Create the main list item
          const mainParagraph = createParagraph([text]);

          // Create the sublist items (a, b, c)
          const sublistParagraphs = indemnitySublist.map((subItem) => {
            return new Paragraph({
              children: [new TextRun(subItem)],
              spacing: {
                after: 100, // 100 twips after the paragraph
              },
              indent: {
                left: 720,
              },
            });
          });

          // Return both the main paragraph and the sublist
          return [mainParagraph, ...sublistParagraphs];
        } else {
          // Return a normal main list item for other paragraphs
          return createParagraph([text]);
        }
      })
      .flat();
  };

  const getHeaderSection = () => {
    if (!loiTemplate) return null;
    switch (loiTemplate.type) {
      case "NAVIGATOR_LOI_OTHER_PORT_NO_BILL":
      case "NAVIGATOR_LOI_NO_BILL":
        return [
          new Paragraph({
            children: [
              new TextRun({ text: `To: \t${ownersManagers}` }),
              new TextRun({ text: `\t${currentDate}` }), // Ensures the date aligns to the right tab stop
            ],
            tabStops: [
              {
                type: TabStopType.LEFT, // This is to ensure 'To:' is left-aligned if necessary
                position: 720, // Slightly away from the absolute left, adjust as needed
              },
              {
                type: TabStopType.RIGHT, // This ensures the date aligns to the right
                position: 9090, // Position in twips (1/20 of a point). Adjust as needed for your layout
              },
            ],
          }),
          createParagraph([`\tThe Owners of the ${vessel}`], 0),
          createParagraph([`\t${address}`], 100),
          createParagraph(["Dear Sirs"], 100),
          createParagraph([`Ship:\t\t\t${vessel}`], 100),
          createParagraph(
            [`Voyage:\t\t\t${portLoading} to ${portDischarge}`],
            100
          ),
          createParagraph([`Cargo:\t\t\t${cargo}`], 100),
          createParagraph(
            [
              `Bill(s) of Lading:\t\t${billOfLadingIdentificationNumber} ${billOfLadingPlaceAndDateOfIssue}`,
            ],
            200
          ),
        ];
      case "NAVIGATOR_LOI_SUBSTITUTE_BL":
        return [
          new Paragraph({
            children: [
              new TextRun({ text: `To: \t${ownersManagers}` }),
              new TextRun({ text: `\t${currentDate}` }), // Ensures the date aligns to the right tab stop
            ],
            tabStops: [
              {
                type: TabStopType.LEFT, // This is to ensure 'To:' is left-aligned if necessary
                position: 720, // Slightly away from the absolute left, adjust as needed
              },
              {
                type: TabStopType.RIGHT, // This ensures the date aligns to the right
                position: 9090, // Position in twips (1/20 of a point). Adjust as needed for your layout
              },
            ],
          }),
          createParagraph([`\tThe Owners of the ${vessel}`], 0),
          createParagraph([`\t${address}`], 100),
          createParagraph(["Dear Sirs"], 100),
          createParagraph([`Ship:\t\t\t${vessel}`], 0),
          createParagraph([`Load port:\t\t${portLoading}`], 0),
          createParagraph([`Delivery port:\t\t${portDischarge}`], 0),
          createParagraph([`Cargo:\t\t\t${cargo}`], 0),
          createParagraph([`Cargo quantity:\t\t${missingValue}`], 0),
          createParagraph(
            [
              `Bill of Lading:\t\t${billOfLadingIdentificationNumber} ${billOfLadingPlaceAndDateOfIssue}`,
            ],
            0
          ),
          createParagraph([`Charter Party:\t\t${missingValue}`], 200),
        ];

      case "NAVIGATOR_LOI_BLENDING_CARGO":
        return [
          createParagraph([`${currentDate}`], 200),
          new Paragraph({
            children: [new TextRun({ text: `To: \t${ownersManagers}` })],
            tabStops: [
              {
                type: TabStopType.LEFT, // This is to ensure 'To:' is left-aligned if necessary
                position: 720, // Slightly away from the absolute left, adjust as needed
              },
            ],
          }),
          createParagraph([`\tThe Owners of the ${vessel}`], 200),

          createParagraph(["Dear Sirs,"], 100),
          createParagraph([`Ship:\t\t\t${vessel}`], 0),
          createParagraph(
            [`Voyage:\t\t\t${portLoading} to ${portDischarge}`],
            0
          ),
          createParagraph([`Cargo:\t\t\t${cargo}`], 0),
          createParagraph(
            [
              `Bill of Lading:\t\t${billOfLadingIdentificationNumber} ${billOfLadingPlaceAndDateOfIssue}`,
            ],
            100
          ),
        ];
      default:
        return [
          createParagraph([`To: \t${ownersManagers}`], 100),
          createParagraph(
            [`\t\t and/or The Owners and/or Managers of the <b>${vessel}</b>`],
            200,
            -1,
            0,
            720
          ),
          createParagraph([`Date:\t<b>${currentDate}</b>`], 200),
          createParagraph([`Dear Sirs`], 100),
          createParagraph([`Vessel:  <b>${vessel}</b>`], 0, -1, 720, 0),
          createParagraph(
            [`Port of Loading:  <b>${portLoading}</b>`],
            0,
            -1,
            720,
            0
          ),
          createParagraph(
            [`Port of Discharge:  <b>${portDischarge}</b>`],
            0,
            -1,
            720,
            0
          ),
          createParagraph([`Cargo:  <b>${cargo}</b>`], 0, -1, 720, 0),
          createParagraph(
            [
              `Bill(s) of Lading:  <b>${billOfLadingIdentificationNumber} ${billOfLadingPlaceAndDateOfIssue}</b>`,
            ],
            100,
            -1,
            720,
            0
          ),
        ];
    }
  };

  const getParagraphs = () => {
    const default_signature = `
            Yours faithfully,
            For and on behalf of ${requestorParty}
            The Requestor
            Signature …………………………………
            Full name: ${requestorName}
            Title: ${requestorDesignation}
    `;

    const defaultLOIParagraphs = () => {
      const { indemnityAgreement, indemnitySublist, indemnityTerms } =
        templateText;
      return [
        getCargoDescriptionParagraph(),
        getIndemnityRequestParagraph(),
        createParagraph([indemnityTerms]),
        ...getIndemnityList(indemnityAgreement, indemnitySublist),
        ...createSection(splitTextToLines(default_signature)),
      ];
    };

    const navigatorParagraphs = () => {
      const {
        word_textBeforeIndemnityList,
        word_indemnityAgreementList,
        signatureText,
      } = templateText;
      return [
        ...createSection(splitTextToLines(word_textBeforeIndemnityList)),
        ...createSection(
          splitTextToLines(word_indemnityAgreementList),
          0,
          true
        ),
        ...createSection(splitTextToLines(signatureText)),
      ];
    };

    switch (loiTemplate.type) {
      case "NAVIGATOR_LOI_NO_BILL":
      case "NAVIGATOR_LOI_OTHER_PORT_NO_BILL":
      case "NAVIGATOR_LOI_SUBSTITUTE_BL":
      case "NAVIGATOR_LOI_BLENDING_CARGO":
        return navigatorParagraphs();
      default:
        return defaultLOIParagraphs();
    }
  };

  const logoBase64 = await imageToBase64(letterhead);
  const doc = new Document({
    numbering: {
      config: [
        {
          reference: "indemnity-numbering",
          levels: [
            {
              level: 0,
              format: "decimal",
              text: "%1.",
              alignment: "left",
            },
            {
              level: 1,
              format: "lowerLetter", // Sublist uses letters (a, b, c)
              text: "(%2)", // Wrap the letter in parentheses
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 1440, hanging: 260 }, // Adjust indentation for sublist
                },
              },
            },
          ],
        },
      ],
    },
    sections: [
      {
        ...(letterhead !== undefined && {
          // Conditional inclusion of header
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      type: "png",
                      data: logoBase64,
                      transformation: {
                        width: 300,
                        height: 80,
                      },
                    }),
                  ],
                  alignment: "right",
                }),
              ],
            }),
          },
        }),
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: "center",
                children: [
                  new TextRun({
                    text: `${requestorParty}`,
                    size: 18,
                    color: "888888",
                  }),
                ],
              }),
              new Paragraph({
                alignment: "center",
                children: [
                  new TextRun({
                    text: `${address}`,
                    size: 18,
                    color: "888888",
                  }),
                ],
              }),
            ],
          }),
        },
        children: [...getHeaderSection(), ...getParagraphs()],
      },
    ],
  });
  return Packer.toBlob(doc);
};

export const handleExportToWordBlobMRLOI = async (formData) => {
  const {
    currentDate,
    vessel,
    owners,
    portLoading,
    portDischarge,
    shipper,
    requestor,
    address,
    changedFields,
    changedHoldFields,
    letterhead,
    title,
  } = formData;

  const strongText = (text) => new TextRun({ text, bold: true });

  // Create the first list of changes
  const changeList =
    Object.keys(changedFields).length > 0
      ? Object.entries(changedFields).map(([, change]) => {
          return new Paragraph({
            children: [
              new TextRun("Change "),
              strongText(`"${change.field}"`),
              new TextRun(" from "),
              strongText(`"${change.originalValue}"`),
              new TextRun(" to "),
              strongText(`"${change.updatedValue}"`),
            ],
            numbering: {
              reference: "change-numbering",
              level: 0,
            },
            spacing: {
              after: 100, // 100 twips after the paragraph
            },
          });
        })
      : []; // No paragraphs if no changes

  // Create the second list for hold changes
  const holdChangeList = changedHoldFields
    .flatMap((holdChange) =>
      holdChange.fields.map((fieldChange) => {
        return new Paragraph({
          children: [
            new TextRun("Change "),
            strongText(`"${toTitleCase(fieldChange.fieldName)}"`),
            new TextRun(" from "),
            strongText(`"${fieldChange.originalValue}"`),
            new TextRun(" to "),
            strongText(`"${fieldChange.updatedValue}"`),
          ],
          numbering: {
            reference: "change-numbering",
            level: 0,
          },
          spacing: {
            after: 100, // 100 twips after the paragraph
          },
        });
      })
    )
    .filter(Boolean); // Filter out any undefined or null values (just in case)

  const logoUrl = await imageToBase64(letterhead);

  const doc = new Document({
    numbering: {
      config: [
        {
          reference: "change-numbering",
          levels: [
            {
              level: 0,
              format: "decimal",
              text: "%1.",
              alignment: "left",
            },
          ],
        },
        {
          reference: "indemnity-numbering",
          levels: [
            {
              level: 0,
              format: "decimal",
              text: "%1.",
              alignment: "left",
            },
            {
              level: 1,
              format: "lowerLetter", // Sublist uses letters (a, b, c)
              text: "(%2)", // Wrap the letter in parentheses
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 1440, hanging: 260 }, // Adjust indentation for sublist
                },
              },
            },
          ],
        },
      ],
    },
    sections: [
      {
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [
                  new ImageRun({
                    data: logoUrl.split(",")[1], // Get the Base64 part
                    transformation: {
                      width: 300,
                      height: 80,
                    },
                  }),
                ],
                alignment: "right",
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: "center",
                children: [
                  new TextRun({
                    text: `${address}`,
                    size: 18,
                    color: "888888",
                  }),
                ],
              }),
            ],
          }),
        },
        children: [
          new Paragraph({
            children: [new TextRun({ text: "To:" }), strongText(`\t${owners}`)],
          }),
          new Paragraph({
            children: [
              new TextRun({ text: "Date:" }),
              strongText(`\t${currentDate}`),
            ],
            spacing: {
              before: 300, // Space before the date line
              after: 200, // Space after the date line
            },
          }),
          new Paragraph({ text: "Dear Sirs" }),
          new Paragraph({
            children: [new TextRun("Vessel:  "), strongText(`${vessel}`)],
            indent: {
              left: 720,
              right: 0,
            },
            alignment: "left",
          }),
          new Paragraph({
            children: [
              new TextRun("Port of Loading:  "),
              strongText(`${portLoading}`),
            ],
            indent: {
              left: 720,
              right: 0,
            },
            alignment: "left",
          }),
          new Paragraph({
            children: [
              new TextRun("Port of Discharge:  "),
              strongText(`${portDischarge}`),
            ],
            indent: {
              left: 720,
              right: 0,
            },
            alignment: "left",
          }),
          new Paragraph({
            children: [new TextRun("Shipper: "), strongText(`${shipper}`)],
            indent: {
              left: 720,
              right: 0,
            },
            alignment: "left",
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "We hereby request you to amend the Mate's Receipt as follows:",
              }),
            ],
            spacing: {
              after: 200,
              before: 200,
            },
          }),
          ...changeList,
          ...holdChangeList,
          new Paragraph({
            children: [
              new TextRun({
                text: "In consideration of your complying with our above request, we hereby agree as follows:",
              }),
            ],
            spacing: {
              after: 200,
              before: 100,
            },
          }),
          ...indemnityList,
          new Paragraph({
            children: [new TextRun({ text: "Yours faithfully," })],
            spacing: {
              before: 400,
            },
          }),
          new Paragraph({ text: "For and on behalf of" }),
          new Paragraph({ text: `${requestor}` }),
          new Paragraph({ text: `${address}` }),
          new Paragraph({
            children: [new TextRun({ text: "The Requestor" })],
            spacing: {
              after: 200,
            },
          }),
          new Paragraph({ text: "…………………………………" }),
          new Paragraph({ text: "Signature" }),
          new Paragraph({ text: `Name: ${requestor}` }),
          new Paragraph({ text: `Title: ${title}` }),
        ],
      },
    ],
  });

  return Packer.toBlob(doc);
};
