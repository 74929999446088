import React from "react";
import PropTypes from "prop-types";
import { Typography, Card, CardContent, Box, useTheme } from "@mui/material";

const OneDocumentUpload = ({ children }) => {
  const theme = useTheme();

  return (
    <Box>
      <Card
        sx={{
          background: theme.palette.background.level1,
          my: 2,
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="intro" sx={{ fontWeight: 600, mb: 3 }}>
              Upload
            </Typography>
          </Box>

          {/* Render the child component(s) */}
          {children}
        </CardContent>
      </Card>
    </Box>
  );
};

OneDocumentUpload.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OneDocumentUpload;
