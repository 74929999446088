import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Box,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";

// Custom Step Icon Component
const CustomStepIcon = ({ active, completed, icon }) => {
  const theme = useTheme();

  return completed ? (
    <CheckIcon sx={{ color: theme.palette.text.secondary }} />
  ) : (
    <Box
      sx={{
        color: theme.palette.text.primary,
        background: active ? theme.palette.primary.main : "transparent",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24,
        fontSize: "0.875rem",
        fontWeight: "bold",
      }}
    >
      {icon}
    </Box>
  );
};

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node.isRequired,
};

// ToolStepProgressBar Component
const ToolStepProgressBar = ({ steps, currentStep, onStepChange }) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: "30%", marginY: 3 }}>
      <Stepper nonLinear activeStep={currentStep}>
        {steps.map((step, index) => (
          <Step key={step} completed={index < currentStep}>
            <StepButton onClick={() => onStepChange(index)}>
              <StepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon
                    {...props}
                    active={index === currentStep}
                    icon={index + 1}
                  />
                )}
                sx={{
                  ".MuiStepLabel-label": {
                    color:
                      index === currentStep
                        ? theme.palette.primary.main
                        : index < currentStep
                          ? theme.palette.text.secondary
                          : theme.palette.text.primary,
                  },
                }}
              >
                {step}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

ToolStepProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number.isRequired,
  onStepChange: PropTypes.func.isRequired,
};

export default ToolStepProgressBar;
