import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { toTitleCase } from "../../helpers";

const MatchingDocumentTable = ({ doc1, doc2, titles, state }) => {
  const theme = useTheme();

  const highlightIfMatch = (value1, value2, important) => {
    if (!value1 || !value2) return;
    // Helper function to normalize text by removing whitespace and common punctuation
    const normalize = (text) => text.replace(/\s+|[.,/]/g, "").toUpperCase();

    // Normalize both values for easier comparison
    const normalizedMrValue = normalize(value1);
    const normalizedBlValue = normalize(value2);

    // Check if values match exactly after normalization
    const isExactMatch = normalizedMrValue === normalizedBlValue;

    // Check if one value is contained within the other
    const isPartialMatch =
      normalizedMrValue.includes(normalizedBlValue) ||
      normalizedBlValue.includes(normalizedMrValue);

    // Apply green highlighting if there is an exact or partial match
    if (important) {
      return isExactMatch || isPartialMatch
        ? { color: theme.palette.semantic.success, fontWeight: "bold" }
        : { color: theme.palette.semantic.error, fontWeight: "bold" };
    } else {
      return isExactMatch || isPartialMatch
        ? { color: theme.palette.semantic.success, fontWeight: "bold" }
        : {};
    }
  };

  const outlineBorder = (state) => {
    const borders = {
      2: `3px solid ${theme.palette.semantic.error}`,
      1: `3px solid ${theme.palette.secondary.main}`,
      0: `3px solid ${theme.palette.primary.main}`,
    };
    return borders[state] || "none"; // Default to "none" if state is undefined
  };

  const boldHeaderStyle = {
    fontWeight: 700,
    fontSize: "16px",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  };

  return (
    <TableContainer
      style={{
        backgroundColor: theme.palette.background.input,
        maxHeight: 800,
        overflowY: "auto",
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                borderRight: `1px solid ${theme.palette.primary.main}`,
                ...boldHeaderStyle,
              }}
            >
              Entry Field
            </TableCell>
            <TableCell
              sx={{
                borderTop: state === 2 ? outlineBorder(state) : "none",
                borderLeft: state === 2 ? outlineBorder(state) : "none",
                borderRight: state === 2 ? outlineBorder(state) : "none",
                ...boldHeaderStyle,
              }}
            >
              {titles[0]}
            </TableCell>
            <TableCell
              sx={{
                borderTop: state !== 2 ? outlineBorder(state) : "none",
                borderLeft: state !== 2 ? outlineBorder(state) : "none",
                borderRight: state !== 2 ? outlineBorder(state) : "none",
                ...boldHeaderStyle,
              }}
            >
              {titles[1]}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[
            "short_name",
            "date",
            "vessel",
            "port_of_loading",
            "port_of_discharge",
            "shipper",
            "shipper_reference",
            "shipping_marks",
          ].map((field, index) => (
            <TableRow key={`detail ${field}-${index}`}>
              <TableCell
                style={{
                  borderRight: `1px solid ${theme.palette.primary.main}`,
                  ...highlightIfMatch(
                    doc1 ? doc1[field] : null,
                    doc2 ? doc2[field] : null
                  ),
                }}
              >
                {toTitleCase(field)}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: state === 2 ? outlineBorder(state) : "none",
                  borderLeft: state === 2 ? outlineBorder(state) : "none",
                  borderBottom:
                    state === 2 && field === "shipping_marks"
                      ? outlineBorder(state)
                      : "1px solid rgba(81, 81, 81, 1)",
                  ...highlightIfMatch(
                    doc1 ? doc1[field] : null,
                    doc2 ? doc2[field] : null
                  ),
                }}
              >
                {doc1 ? doc1[field] : "-"}
              </TableCell>
              <TableCell
                sx={{
                  borderLeft: state !== 2 ? outlineBorder(state) : "none",
                  borderRight: state !== 2 ? outlineBorder(state) : "none",
                  borderBottom:
                    state !== 2 && field === "shipping_marks"
                      ? outlineBorder(state)
                      : "1px solid rgba(81, 81, 81, 1)",
                  ...highlightIfMatch(
                    doc1 ? doc1[field] : null,
                    doc2 ? doc2[field] : null
                  ),
                }}
              >
                {doc2 ? doc2[field] : "-"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

MatchingDocumentTable.propTypes = {
  doc1: PropTypes.object.isRequired,
  doc2: PropTypes.object,
  titles: PropTypes.array.isRequired,
  state: PropTypes.number.isRequired,
};

export default MatchingDocumentTable;
