import React from "react";
import { Box, Typography, Tooltip, useTheme } from "@mui/material";
import PropTypes from "prop-types";

const EditableFieldTemplate = ({
  value,
  context,
  nullContext,
  confidence,
  updateFormData,
  valueName,
  isEditable,
}) => {
  const theme = useTheme();
  // Function to get the style dynamically based on the value
  const getStrongStyle = (value) => {
    let color = "inherit";
    switch (value) {
      case "Input Needed":
        color = theme.palette.semantic.warning;
        break;
      case `MISSING ${nullContext}`:
        color = theme.palette.semantic.error;
        break;
      default:
        color = getTooltipStyle(confidence);
        break;
    }
    return {
      color: color,
      fontWeight: "bold",
      textDecoration: "underline",
      textDecorationStyle: "dotted",
    };
  };

  // Function to determine the color of the confidence level in the tooltip
  const getTooltipStyle = (confidence) => {
    switch (confidence) {
      case "HIGH":
        return theme.palette.semantic.success;
      case "MEDIUM":
        return theme.palette.semantic.warning;
      case "LOW":
        return theme.palette.semantic.error;
      default:
        return theme.palette.semantic.success;
    }
  };

  return (
    <Tooltip
      title={
        <Box>
          <Typography>Context: {context ? context : nullContext}</Typography>
          <Typography style={{ color: getTooltipStyle(confidence) }}>
            Confidence: {confidence || "Unknown"}
          </Typography>
        </Box>
      }
    >
      <Typography
        component="span"
        display={"inline"}
        suppressContentEditableWarning={true}
        contentEditable={isEditable}
        onBlur={(e) => updateFormData(valueName, e.currentTarget.textContent)}
      >
        <strong style={getStrongStyle(value)}>{value}</strong>
      </Typography>
    </Tooltip>
  );
};

EditableFieldTemplate.propTypes = {
  value: PropTypes.string.isRequired,
  context: PropTypes.string,
  nullContext: PropTypes.string,
  confidence: PropTypes.string,
  updateFormData: PropTypes.func,
  valueName: PropTypes.string,
  isEditable: PropTypes.bool.isRequired,
};

export default EditableFieldTemplate;
