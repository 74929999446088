import React from "react";
import { Box, Button, Grid2 } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const ExportButtons = ({ actions, validation }) => {
  const navigate = useNavigate();

  const buttonConfig = [
    {
      label: "Cancel",
      variant: "outlined",
      onClick: () => navigate("/dashboard"),
      disabled: false,
      startIcon: null,
      style: { border: "transparent" },
    },
    {
      label: "Copy to Clipboard",
      variant: "outlined",
      onClick: actions.handleCopy,
      disabled: validation,
      startIcon: <ContentCopyIcon />,
    },
    {
      label: "Save As .pdf",
      variant: "contained",
      onClick: actions.handleExportPDF,
      disabled: validation,
      startIcon: <PictureAsPdfIcon />,
    },
    {
      label: "Save As .docx",
      variant: "contained",
      onClick: actions.handleExportDocx,
      disabled: validation,
      startIcon: <TextSnippetIcon />,
    },
  ];

  return (
    <Box>
      <Grid2 container spacing={2}>
        {buttonConfig.map((config, index) => (
          <Grid2 key={index} size={index === 0 ? 1.5 : 3.5}>
            <Button
              variant={config.variant}
              fullWidth
              onClick={config.onClick}
              disabled={config.disabled}
              startIcon={config.startIcon}
              sx={config.style}
            >
              {config.label}
            </Button>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

ExportButtons.propTypes = {
  actions: PropTypes.shape({
    handleCopy: PropTypes.func.isRequired,
    handleExportPDF: PropTypes.func.isRequired,
    handleExportDocx: PropTypes.func.isRequired,
  }).isRequired,
  validation: PropTypes.bool.isRequired,
};

export default ExportButtons;
