import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableChartIcon from "@mui/icons-material/TableChart";

const ActionToolBar = ({ onCopy, onSavePdf, onSaveExcel }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        gap: 2,
      }}
    >
      <Button
        variant="outlined"
        startIcon={<ContentCopyIcon />}
        onClick={onCopy()}
        sx={{ textTransform: "none" }}
      >
        Copy to Clipboard
      </Button>
      <Button
        variant="contained"
        startIcon={<PictureAsPdfIcon />}
        onClick={onSavePdf()}
        sx={{ textTransform: "none" }}
      >
        Save as PDF
      </Button>
      <Button
        variant="contained"
        startIcon={<TableChartIcon />}
        onClick={onSaveExcel()}
        sx={{ textTransform: "none" }}
      >
        Save as Excel
      </Button>
    </Box>
  );
};

ActionToolBar.propTypes = {
  onCopy: PropTypes.func.isRequired,
  onSavePdf: PropTypes.func.isRequired,
  onSaveExcel: PropTypes.func.isRequired,
};

export default ActionToolBar;
