import React from "react";
import { Grid2, TextField } from "@mui/material";
import PropTypes from "prop-types";

const PageSelector = ({
  pageCount,
  startPage,
  setStartPage,
  endPage,
  setEndPage,
}) => {
  PageSelector.propTypes = {
    pageCount: PropTypes.number.isRequired,
    startPage: PropTypes.number.isRequired,
    setStartPage: PropTypes.func.isRequired,
    endPage: PropTypes.number.isRequired,
    setEndPage: PropTypes.func.isRequired,
  };
  return (
    <>
      <Grid2 size={3}>
        <TextField
          label="Start Page"
          type="number"
          value={startPage}
          onChange={(e) => {
            const newValue =
              e.target.value === ""
                ? ""
                : Math.min(Math.max(1, Number(e.target.value)), pageCount);
            setStartPage(newValue); // Update state only if input is within range or empty
          }}
          inputProps={{
            min: 1,
            max: pageCount,
            onInput: (e) => {
              const newValue =
                e.target.value === ""
                  ? ""
                  : Math.min(Math.max(1, Number(e.target.value)), pageCount);
              e.target.value = newValue;
            },
          }}
          disabled={pageCount === 1}
          sx={{ mt: 2, width: "100%" }}
        />
      </Grid2>
      <Grid2 size={3}>
        <TextField
          label="End Page"
          type="number"
          value={endPage}
          onChange={(e) => {
            const newValue =
              e.target.value === ""
                ? ""
                : Math.min(Math.max(1, Number(e.target.value)), pageCount);
            setEndPage(newValue);
          }}
          inputProps={{
            min: 1,
            max: pageCount,
            onInput: (e) => {
              const newValue =
                e.target.value === ""
                  ? ""
                  : Math.min(Math.max(1, Number(e.target.value)), pageCount);
              e.target.value = newValue;
            },
          }}
          disabled={pageCount === 1}
          sx={{ mt: 2, width: "100%" }}
        />
      </Grid2>
    </>
  );
};

export default PageSelector;
