export const getNavigatorOtherPortNoBillLOITemplate = ({
  shipper,
  consignee,
  portDischarge,
  requestorParty,
  deliveryParty,
  deliveryPlace,
}) => {
  return {
    templateLabel: "Other Port No Bill",

    indemnityTerms:
      "In consideration of your complying with our above request, we hereby agree as follows:",

    indemnityAgreement: [
      "1. To indemnify you, your servants and agents and to hold all of you harmless in respect of any liability, loss, damage or expense of whatsoever nature which you may sustain by reason of the ship proceeding and giving delivery of the cargo in accordance with our request.",
      "2. In the event of any proceedings being commenced against you or any of your servants or agents in connection with the ship proceeding and giving delivery of the cargo as aforesaid, to provide you or them on demand with sufficient funds to defend the same.",
      "3. If, in connection with the delivery of the cargo as aforesaid, the ship, or any other ship or property in the same or associated ownership, management or control, should be arrested or detained or should the arrest or detention thereof be threatened, or should there be any interference in the use or trading of the vessel (whether by virtue of a caveat being entered on the ship’s registry or otherwise howsoever), to provide on demand such bail or other security as may be required to prevent such arrest or detention or to secure the release of such ship or property or to remove such interference and to indemnify you in respect of any liability, loss, damage or expense caused by such arrest or detention or threatened arrest or detention or such interference, whether or not such arrest or detention or threatened arrest or detention or such interference may be justified.",
      "4. If the place at which we have asked you to make delivery is a bulk liquid or gas terminal or facility, or another ship, lighter or barge, then delivery to such terminal, facility, ship, lighter or barge shall be deemed to be delivery to the party to whom we have requested you to make such delivery.",
      "5. As soon as all original bills of lading for the above cargo shall have come into our possession, to deliver the same to you, or otherwise to cause all original bills of lading to be delivered to you.",
      "6. The liability of each and every person under this indemnity shall be joint and several and shall not be conditional upon your proceeding first against any person, whether or not such person is party to or liable under this indemnity.",
      "7. This indemnity shall be governed by and construed in accordance with English law and each and every person liable under this indemnity shall at your request submit to the jurisdiction of the High Court of Justice of England.",
    ],

    indemnitySublist: [],

    signatureText: `
            Yours faithfully,
            For and on behalf of
            [insert name of Requestor]
            The Requestor


            .......................................
            Signature
        `,

    word_textBeforeIndemnityList: `
        The above cargo was shipped on the above vessel by ${shipper} and consigned
        to ${consignee} for delivery at the port of ${portDischarge} but we, ${requestorParty}, hereby request you to order the vessel to proceed to and deliver the said cargo at ${deliveryPlace} to “${deliveryParty}” or to such party as you believe to be or to represent ${deliveryParty} or to be acting on behalf of ${deliveryParty}" without production of the original bill of lading.

        In consideration of your complying with our above request, we hereby agree as follows :-

        `,

    word_indemnityAgreementList: `
            To indemnify you, your servants and agents and to hold all of you harmless in respect of any liability, loss, damage or expense of whatsoever nature which you may sustain by reason of the ship proceeding and giving delivery of the cargo in accordance with our request.
            In the event of any proceedings being commenced against you or any of your servants or agents in connection with the ship proceeding and giving delivery of the cargo as aforesaid, to provide you or them on demand with sufficient funds to defend the same.
            If, in connection with the delivery of the cargo as aforesaid, the ship, or any other ship or property in the same or associated ownership, management or control, should be arrested or detained or should the arrest or detention thereof be threatened, or should there be any interference in the use or trading of the vessel (whether by virtue of a caveat being entered on the ship’s registry or otherwise howsoever), to provide on demand such bail or other security as may be required to prevent such arrest or detention or to secure the release of such ship or property or to remove such interference and to indemnify you in respect of any liability, loss, damage or expense caused by such arrest or detention or threatened arrest or detention or such interference, whether or not such arrest or detention or threatened arrest or detention or such interference may be justified.
            If the place at which we have asked you to make delivery is a bulk liquid or gas terminal or facility, or another ship, lighter or barge, then delivery to such terminal, facility, ship, lighter or barge shall be deemed to be delivery to the party to whom we have requested you to make such delivery.
            As soon as all original bills of lading for the above cargo shall have come into our possession, to deliver the same to you, or otherwise to cause all original bills of lading to be delivered to you.
            The liability of each and every person under this indemnity shall be joint and several and shall not be conditional upon your proceeding first against any person, whether or not such person is party to or liable under this indemnity.
            This indemnity shall be governed by and construed in accordance with English law and each and every person liable under this indemnity shall at your request submit to the jurisdiction of the High Court of Justice of England.
        `,
  };
};
