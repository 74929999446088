import axios from "axios";
import { API_URL } from "./config";

export const generateInstructions = async (
  fixture_recap_text,
  organisationId
) => {
  const formData = new FormData();

  formData.append("original_text", fixture_recap_text);
  formData.append("organisation_id", organisationId);

  try {
    const response = await axios.post(
      `${API_URL}/voyage_instruction/extract_voyage_instruction`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true, // If you're sending credentials like cookies
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error generating voyage instructions:", error);
    throw error;
  }
};
