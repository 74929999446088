import React from "react";
import PropTypes from "prop-types";
import { Grid2 } from "@mui/material";

const ComparisonDetails = ({ children }) => {
  const childrenArray = React.Children.toArray(children);

  if (childrenArray.length !== 2)
    console.error(
      `ComparisonDetails expects exactly 2 children, but received ${childrenArray.length}.`
    );

  return (
    <Grid2 container spacing={3}>
      {childrenArray.map((child, index) => (
        <Grid2 size={6} key={index}>
          {React.cloneElement(child)}
        </Grid2>
      ))}
    </Grid2>
  );
};
ComparisonDetails.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ComparisonDetails;
