import axios from "axios";
import { API_URL } from "./config";

export const compareMatesReceipts = async (
  file1,
  file2,
  options = {
    useOCR: true,
    useVision: true,
    useContentCache: true,
    useComparisonCache: true,
    organisationId: "",
  }
) => {
  const formData = new FormData();
  formData.append("original_mr", file1);
  formData.append("updated_mr", file2);
  formData.append("ocr", options.useOCR);
  formData.append("vision", options.useVision);
  formData.append("use_content_cache", options.useContentCache);
  formData.append("use_mr_cache", true);
  formData.append("use_mr_comparison_cache", options.useComparisonCache);
  formData.append("organisation_id", options.organisationId);

  try {
    const response = await axios.post(
      `${API_URL}/mr_comparison/compare_mates_receipts/files`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true, // If you're sending credentials like cookies
      }
    );

    return response.data; // Axios automatically parses JSON, so no need to manually call `response.json()`
  } catch (error) {
    console.error("Failed to compare receipts:", error);
    throw error;
  }
};

export const extractMRandMatch = async (
  file1,
  file2,
  options = {
    useOCR: true,
    useVision: true,
    useComparisonCache: true,
    organisationId: "",
  }
) => {
  const formData = new FormData();
  formData.append("mr1_file", file1);
  formData.append("mr2_file", file2);
  formData.append("ocr", options.useOCR);
  formData.append("vision", options.useVision);
  formData.append("use_cache", options.useComparisonCache);
  formData.append("organisation_id", options.organisationId);
  formData.append("mr1_one_entity_per_page", true);
  formData.append("mr2_one_entity_per_page", true);
  formData.append("min_match_score", 0.7);
  const config = [
    { field: "mr_id", weight: 1.0 },
    { field: "shipping_marks", weight: 1.0 },
  ];
  formData.append("match_configs", JSON.stringify(config));

  try {
    const response = await axios.post(
      `${API_URL}/mr_comparison/extract_mr_and_match`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true, // If you're sending credentials like cookies
      }
    );

    return response.data; // Axios automatically parses JSON, so no need to manually call `response.json()`
  } catch (error) {
    console.error("Failed to compare receipts:", error);
    throw error;
  }
};

export const extractaMRBlMatch = async (
  file1,
  file2,
  options = {
    useMROCR: true,
    useMRVision: true,
    useBLOCR: true,
    useBLVision: true,
    useContentCache: true,
    organisationId: "",
  }
) => {
  const formData = new FormData();

  // Check if file1 is a list of files or a single file
  if (Array.isArray(file1)) {
    file1.forEach((file) => {
      formData.append("bl_files", file);
    });
  } else {
    formData.append("bl_files", file1);
  }

  formData.append("mr_file", file2);
  formData.append("mr_ocr", options.useMROCR);
  formData.append("mr_vision", options.useMRVision);
  formData.append("bl_ocr", options.useBLOCR);
  formData.append("bl_vision", options.useBLVision);
  formData.append("use_cache", options.useContentCache);
  formData.append("organisation_id", options.organisationId);

  // Adding the new fields
  formData.append("min_match_score", 0.7);
  const config = [
    { field: "shipper_reference", weight: 1.0 },
    { field: "shipping_marks", weight: 1.0 },
  ];
  formData.append("match_configs", JSON.stringify(config));
  formData.append("mr_one_entity_per_page", true);
  formData.append("bl_one_entity_per_page", true);

  try {
    const response = await axios.post(
      `${API_URL}/bl_comparison/extract_mr_bl_and_match`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Failed to compare receipts:", error);
    throw error;
  }
};

export const compareMRBL = async (
  file1,
  file2,
  options = {
    useOCR: true,
    useVision: true,
    useCache: true,
    organisationId: "",
  }
) => {
  const formData = new FormData();
  if (Array.isArray(file1)) {
    file1.forEach((file) => {
      formData.append("bl_file", file);
    });
  } else {
    formData.append("bl_file", file1);
  }
  formData.append("mr_file", file2);
  formData.append("ocr", options.useOCR);
  formData.append("vision", options.useVision);
  formData.append("use_cache", options.useCache);
  formData.append("organisation_id", options.organisationId);

  try {
    const response = await axios.post(
      `${API_URL}/bl_comparison/compare_mr_bl/files`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Failed to comapre MR to BL:", error);
    throw error;
  }
};

export const compareMLBLObject = async (
  mrObject,
  blObject,
  organisationId,
  cache
) => {
  const formData = new FormData();
  formData.append("mates_receipt", JSON.stringify(mrObject));
  formData.append("bill_of_lading", JSON.stringify(blObject));
  formData.append("use_cache", cache);
  formData.append("organisation_id", organisationId);

  try {
    const response = await axios.post(
      `${API_URL}/bl_comparison/compare_mr_bl/objects`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true, // If you're sending credentials like cookies
      }
    );

    return response.data; // Axios automatically parses JSON, so no need to manually call `response.json()`
  } catch (error) {
    console.error("Failed to compare ml to bl:", error);
    throw error;
  }
};

export const compareMR2MRObject = async (
  mrObject1,
  mrObject2,
  organisationId,
  cache
) => {
  const formData = new FormData();
  formData.append("mates_receipt1", JSON.stringify(mrObject1));
  formData.append("mates_receipt2", JSON.stringify(mrObject2));
  formData.append("use_cache", cache);
  formData.append("organisation_id", organisationId);

  try {
    const response = await axios.post(
      `${API_URL}/mr_comparison/compare_mates_receipts/objects`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true, // If you're sending credentials like cookies
      }
    );

    return response.data; // Axios automatically parses JSON, so no need to manually call `response.json()`
  } catch (error) {
    console.error("Failed to compare ml to mr:", error);
    throw error;
  }
};
