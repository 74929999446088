import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CloseFooter = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Button
        onClick={() => {
          navigate("/dashboard");
        }}
        variant="outlined"
        sx={{ width: "100%" }}
      >
        Close
      </Button>
    </Box>
  );
};

export default CloseFooter;
