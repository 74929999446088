import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid2,
  useTheme,
} from "@mui/material";

const TwoDocumentUpload = ({ children }) => {
  const theme = useTheme();

  return (
    <Box>
      <Card
        sx={{
          background: theme.palette.background.level1,
          my: 2,
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Typography variant="intro" sx={{ fontWeight: 600 }}>
            Upload
          </Typography>
          {/* Grid Layout for Upload Sections */}
          <Grid2 container spacing={2} sx={{ mt: 3 }}>
            {children}
          </Grid2>
        </CardContent>
      </Card>
    </Box>
  );
};

TwoDocumentUpload.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TwoDocumentUpload;
