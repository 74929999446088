// UserContext.js
import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// Create a UserContext with default values
export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  UserProvider.propTypes = {
    children: PropTypes.any,
  };
  const [user, setUser] = useState(null);

  // Fetch user data from localStorage or an API (or any other source)
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("userSession")); // Update to match the session storage key
    setUser(storedUser); // If a session exists, set the user
  }, []);

  // Function to update user data (useful for login or profile updates)
  const updateUser = (newUserData) => {
    setUser(newUserData);
    localStorage.setItem("userSession", JSON.stringify(newUserData)); // Save user session to localStorage
  };

  // Function to clear user data (useful for logout)
  const clearUser = () => {
    setUser(null);
    localStorage.removeItem("userSession"); // Remove session from localStorage
  };

  return (
    <UserContext.Provider value={{ user, updateUser, clearUser }}>
      {children}
    </UserContext.Provider>
  );
};
