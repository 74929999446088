import { saveAs } from "file-saver";
import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
import {
  getTemplateFunctions,
  splitTextToLines,
  voyageInstructionTemplates,
} from "../templates/utils";
import { getChristianiaCargoTable } from "../templates/ChristianiaVITemplate";

export const normalText = (text) => new TextRun({ text });
export const strongText = (text) => new TextRun({ text, bold: true });
export const italicText = (text) => new TextRun({ text, italics: true });
export const strongItalicText = (text) =>
  new TextRun({ text, bold: true, italics: true });

// Goes over <b>, <i>, <c> tags and formats text accordingly
export const formatText = (text) => {
  const parts = [];
  const combinedRegex = /<(b|i|c)>(.*?)<\/\1>/g;
  let lastIndex = 0;
  let match;

  // Finds all sentences between <b>, <i>, <c> tags
  while ((match = combinedRegex.exec(text)) !== null) {
    const [fullMatch, tag, content] = match;

    // Adds normal text before matchedformatted text
    if (match.index > lastIndex) {
      parts.push(normalText(text.substring(lastIndex, match.index)));
    }

    // Adds formatted text based on the tag
    if (tag === "b") {
      parts.push(strongText(content));
    } else if (tag === "i") {
      parts.push(italicText(content));
    } else if (tag === "c") {
      parts.push(strongItalicText(content));
    }

    lastIndex = match.index + fullMatch.length;
  }

  // Add any remaining non-formatted text after the last match
  if (lastIndex < text.length) {
    parts.push(normalText(text.substring(lastIndex)));
  }

  return parts;
};

export const handleExportToWordBlob = async (formData, organisationId) => {
  const {
    vessel,
    cpDate,
    charterer,
    laycan,
    speed,
    cleaning,
    heating,
    cargo,
    loadingAgents,
    loadAgentEmail,
    dischargeAgents,
    dischargeAgentEmail,
    dischargeRate,
    portOfLoading,
    portOfDischarge,
    previousPort,
    brokers,
    brokersEmail,
    missingValue,
  } = formData;

  const templateKey =
    voyageInstructionTemplates[organisationId] ||
    voyageInstructionTemplates.default;
  const templateText = await getTemplateFunctions[templateKey]({
    brokers,
    brokersEmail,
    cargo,
    charterer,
    cleaning,
    cpDate,
    dischargeAgents,
    dischargeAgentEmail,
    dischargeRate,
    heating,
    laycan,
    loadAgentEmail,
    loadingAgents,
    missingValue,
    portOfDischarge,
    portOfLoading,
    previousPort,
    speed,
    vessel,
  });

  // Helper function to create a Paragraph
  const createParagraph = (textRuns, spacingAfter = 100, bulletLevel = -1) => {
    const leftIndent = { 0: 720, 1: 720 };
    const hangingIndent = { 0: 360, 1: 360 };

    return new Paragraph({
      children: textRuns.flatMap((text) => formatText(text)),
      numbering:
        bulletLevel >= 0
          ? { reference: "shipster-numbering", level: bulletLevel }
          : undefined,
      indent:
        bulletLevel >= 0
          ? {
              left: leftIndent[bulletLevel],
              hanging: hangingIndent[bulletLevel],
            }
          : undefined,
      spacing: { after: spacingAfter },
    });
  };

  // Creates a section of paragraphs
  const createSection = (texts, bulletLevel = -1) => {
    let result = [];
    for (let i = 0; i < texts.length; i++) {
      const currentText = texts[i];
      const nextText = texts[i + 1];

      // If newline, adds spacing 200 to simulate a new line
      const spacingAfter = nextText && !nextText.trim() ? 200 : 0;
      result.push(createParagraph([currentText], spacingAfter, bulletLevel));
    }
    return result;
  };

  // Gets all the paragraphs for the template
  const getParagraphs = () => {
    const centurionParagraphs = () => {
      const { text1 } = templateText;
      return [...createSection(splitTextToLines(text1))];
    };

    const christianiaParagraphs = () => {
      const { text1, text2, cleaningList, text3, regardingList, noteText } =
        templateText;
      return [
        ...createSection(splitTextToLines(text1)),
        createParagraph([""], 100),
        getChristianiaCargoTable(cargo, missingValue),
        createParagraph([""], 100),
        ...createSection(splitTextToLines(text2)),
        ...createSection(splitTextToLines(cleaningList), 0),
        createParagraph([""], 100),
        ...createSection(splitTextToLines(text3)),
        ...createSection(splitTextToLines(regardingList), 1),
        createParagraph([""], 100),
        ...createSection(splitTextToLines(noteText)),
      ];
    };

    const falconParagraphs = () => {
      const { text1 } = templateText;
      return [...createSection(splitTextToLines(text1))];
    };

    switch (organisationId) {
      case "centurion":
        return centurionParagraphs();
      case "falcon":
        return falconParagraphs();
      default:
        return christianiaParagraphs();
    }
  };

  const doc = new Document({
    numbering: {
      config: [
        {
          reference: "shipster-numbering",
          levels: [
            {
              level: 0,
              format: "bullet",
              text: "•",
              alignment: AlignmentType.START,
              paragraph: {
                indent: { left: 360, hanging: 360 }, // Indentation for first-level bullets
              },
            },
            {
              level: 1,
              format: "lowerLetter", // Sublist uses letters (a, b, c)
              text: "-", // Wrap the letter in parentheses
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: { left: 360, hanging: 180 }, // Adjust indentation for sublist
                },
              },
            },
          ],
        },
      ],
    },
    sections: [
      {
        children: getParagraphs(),
      },
    ],
  });

  return Packer.toBlob(doc);
};

export const handleSaveAsWord = async (formData, organisationId) => {
  const wordDocBlob = await handleExportToWordBlob(formData, organisationId);
  saveAs(wordDocBlob, `Voyage_Instructions.docx`);
};
