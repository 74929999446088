import { getDefaultNoBillLOITemplate } from "./defaultNoBillLOITemplate.js";
import { getDefaultOtherPortLOITemplate } from "./defaultOtherPortLOITemplate.js";
import { getDefaultOtherPortNoBillLOITemplate } from "./defaultOtherPortNoBillLOITemplate.js";
import { getChristianiaVITemplate } from "./ChristianiaVITemplate.js";
import { getCenturionVITemplate } from "./CenturionVITemplate.js";
import { getFalconVITemplate } from "./FalconVITemplate.js";
import { getNavigatorNoBillLOITemplate } from "./navigator_lois/noBillLOITemplate.js";
import { getNavigatorOtherPortNoBillLOITemplate } from "./navigator_lois/otherPortNoBillLOITemplate.js";
import { getNavigatorSubstituteBLLOITemplate } from "./navigator_lois/substituteBillLOITemplate.js";
import { getNavigatorBendingCargoLOITemplate } from "./navigator_lois/blendingCargoLOITemplate.js";

export const defaultLOITemplates = [
  { label: "No bill", type: "DEFAULT_LOI_NO_BILL" },
  { label: "Other port", type: "DEFAULT_LOI_OTHER_PORT" },
  { label: "No bill and other port", type: "DEFAULT_LOI_NO_BILL_OTHER_PORT" },
];

export const navigatorLOITemplates = [
  { label: "Delivery of cargo without BL", type: "NAVIGATOR_LOI_NO_BILL" },
  { label: "No BL and other port", type: "NAVIGATOR_LOI_OTHER_PORT_NO_BILL" },
  { label: "Substitute BL", type: "NAVIGATOR_LOI_SUBSTITUTE_BL" },
  {
    label: "Commingling or blending cargo",
    type: "NAVIGATOR_LOI_BLENDING_CARGO",
  },
];

export const loiTemplateChoices = {
  navigator: navigatorLOITemplates,
  default: defaultLOITemplates,
};

export const voyageInstructionTemplates = {
  centurion: "CENTURION_VOYAGE_INSTRUCTIONS",
  christiania: "CHRISTIANIA_VOYAGE_INSTRUCTIONS",
  falcon: "FALCON_VOYAGE_INSTRUCTIONS",
  default: "CHRISTIANIA_VOYAGE_INSTRUCTIONS",
};
export const getTemplateFunctions = {
  DEFAULT_LOI_NO_BILL: getDefaultNoBillLOITemplate,
  DEFAULT_LOI_OTHER_PORT: getDefaultOtherPortLOITemplate,
  DEFAULT_LOI_NO_BILL_OTHER_PORT: getDefaultOtherPortNoBillLOITemplate,

  NAVIGATOR_LOI_NO_BILL: getNavigatorNoBillLOITemplate,
  NAVIGATOR_LOI_OTHER_PORT_NO_BILL: getNavigatorOtherPortNoBillLOITemplate,
  NAVIGATOR_LOI_SUBSTITUTE_BL: getNavigatorSubstituteBLLOITemplate,
  NAVIGATOR_LOI_BLENDING_CARGO: getNavigatorBendingCargoLOITemplate,

  CHRISTIANIA_VOYAGE_INSTRUCTIONS: getChristianiaVITemplate,
  CENTURION_VOYAGE_INSTRUCTIONS: getCenturionVITemplate,
  FALCON_VOYAGE_INSTRUCTIONS: getFalconVITemplate,
};

// Splits text into lines with newlines as ""
export const splitTextToLines = (text) => {
  const result = text
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line !== "" || line.trim().length === 0);

  // Removes first and last empty lines
  if (result[0] === "") {
    result.shift();
  }
  if (result[result.length - 1] === "") {
    result.pop();
  }

  return result;
};
