import axios from "axios";
import { API_URL } from "./config";

export const compareCargoPdfs = async (
  billOfLadings,
  voyageManifests,
  options = {
    useOCR: true,
    useVision: true,
    useContentCache: true,
    useCargoCache: true,
    organisationId: "",
  }
) => {
  const formData = new FormData();
  billOfLadings.forEach((file) => {
    formData.append("bill_of_ladings", file);
  });
  voyageManifests.forEach((file) => {
    formData.append("voyage_manifests", file);
  });
  formData.append("ocr", options.useOCR);
  formData.append("vision", options.useVision);
  formData.append("use_content_cache", options.useContentCache);
  formData.append("use_cargo_cache", options.useCargoCache);
  formData.append("use_cargo_comparison_cache", true);
  formData.append("organisation_id", options.organisationId);
  try {
    const response = await axios.post(
      `${API_URL}/cargo_comparison/compare_cargo/files`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true, // If you're sending credentials like cookies
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error comparing cargo PDFs:", error);
    throw error;
  }
};
