import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  CardContent,
  Button,
  Box,
  Collapse,
  Switch,
  FormControlLabel,
  useTheme,
} from "@mui/material";

const GenerationCard = ({
  children,
  title,
  multiple,
  toggleEdit,
  handleToggleExport,
  id,
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(!multiple);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    toggleEdit();
  };

  // Normalize children into an array
  const childrenArray = React.Children.toArray(children);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderRadius: 3,
        boxShadow: "0px 4px 8px 0px rgba(38, 40, 44, 0.08)",
        marginTop: 2,
        marginBottom: 4,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography variant="intro" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {multiple && (
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => handleToggleExport(id, e.target.checked)}
                />
              }
              label="Include in export"
            />
          )}
          <Button
            onClick={toggleExpand}
            variant="contained"
            sx={{ marginLeft: 2 }}
          >
            {isExpanded ? "Save" : "Edit"}
          </Button>
        </Box>
      </Box>

      {/* Card Content */}
      <Collapse in={isExpanded}>
        <CardContent>
          {childrenArray.map((child, index) => (
            <Box key={`child - ${index}`}>{React.cloneElement(child)}</Box>
          ))}
        </CardContent>
      </Collapse>
    </Card>
  );
};

GenerationCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  handleToggleExport: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default GenerationCard;
