import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { approvedUsers } from "../../config/loginDetails";
import { UserContext } from "../../context/UserContext";
import { applyGlobalStyles } from "../../applyStyles";
import { globalStyles } from "../../styles";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const LoginScreen = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const { updateUser } = useContext(UserContext);
  const theme = useTheme();

  useEffect(() => {
    // Apply login-specific background
    document.body.classList.add("login-background");
    applyGlobalStyles(globalStyles);

    return () => {
      // Remove login-specific background
      document.body.classList.remove("login-background");
      applyGlobalStyles(globalStyles);
    };
  }, []);

  const handleLogin = () => {
    const validUser = approvedUsers.find(
      (user) => user.username === username && user.password === password
    );

    if (validUser) {
      const userData = {
        username: validUser.username,
        orgId: validUser.organisation,
        fullName: validUser.fullName || "John Doe",
        isAdmin: validUser.isAdmin,
      };
      updateUser(userData);
      setError("");
    } else {
      setError("Invalid username or password.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "90vh",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 475,
            padding: 4,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              textAlign: "left",
              marginBottom: 4,
            }}
          >
            <img
              src="/assets/SHIPSTER.png"
              alt="SHIPSTER"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </Box>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.primary, fontWeight: 600 }}
          >
            Username
          </Typography>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            margin="normal"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineOutlinedIcon
                      sx={{ color: theme.palette.primary.main }}
                    />
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: "#101214",
                  border: "1px solid #1E2125",
                  color: theme.palette.text.primary,
                },
              },
              inputLabel: {
                style: { color: "#5C6772" },
              },
            }}
          />
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.primary, mt: 1, fontWeight: 600 }}
          >
            Password
          </Typography>
          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            onKeyDown={handleKeyDown}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon
                      sx={{ color: theme.palette.primary.main }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? (
                        <VisibilityOff
                          sx={{ color: theme.palette.text.primary }}
                        />
                      ) : (
                        <Visibility
                          sx={{ color: theme.palette.text.primary }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: "#101214",
                  border: "1px solid #1E2125",
                  color: theme.palette.text.primary,
                },
              },
              inputLabel: {
                style: { color: "#5C6772" },
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox sx={{ color: theme.palette.background.level3 }} />
            }
            label={
              <Typography sx={{ color: theme.palette.text.primary }}>
                Remember me
              </Typography>
            }
          />
          {error && (
            <Typography
              color="error"
              variant="body1"
              sx={{ marginTop: 2, textAlign: "center" }}
            >
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
            sx={{
              marginTop: 2,
              padding: 1.5,
            }}
          >
            Log in
          </Button>
          <Typography
            sx={{
              textAlign: "left",
              color: "#007bff",
              marginTop: 2,
              cursor: "pointer",
            }}
            onClick={() => alert("Redirect to reset password")}
          >
            Forgot password?
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
          color: "white",
        }}
      >
        <Typography variant="display" sx={{ marginBottom: 4, fontWeight: 500 }}>
          <span style={{ color: theme.palette.primary.main }}>Embark</span> on a{" "}
          <span style={{ color: theme.palette.primary.main }}>voyage</span> like
          no other
        </Typography>
        <Typography
          variant="intro"
          sx={{ textAlign: "left", fontWeight: 275, maxWidth: 599 }}
        >
          We build custom digital solutions and applications crafted to solve
          your unique challenges in your business.
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginScreen;
