import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  IconButton,
  useTheme,
} from "@mui/material";
import GradientAutoAwesomeIcon from "../icons/GradientAutoAwesomeIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const VarianceSummary = ({ summary }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);

  // Check if the summary contains '-' and split accordingly
  const summaryParts = summary.includes("-") ? summary.split("-") : [summary];

  // Determine if the first part contains meaningful text
  const firstPart = summaryParts[0].trim();
  const remainingParts = firstPart
    ? summaryParts.slice(1)
    : summaryParts.slice(1);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.default,
        color: "inherit",
        position: "relative",
        borderRadius: 2,
        overflow: "hidden", // Ensures the border effect is clipped
        zIndex: 0,
        ":before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          zIndex: -1,
          borderRadius: "16px",
          padding: "2px", // Border width
          WebkitMask: `linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)`,
          WebkitMaskComposite: "destination-out",
          maskComposite: "exclude",
        },
        marginTop: 2,
        marginBottom: 4,
      }}
    >
      <CardContent>
        <Typography
          variant="intro"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: 600,
            marginBottom: isExpanded ? 1 : 0,
          }}
        >
          <span>
            <GradientAutoAwesomeIcon />
            &nbsp;Variance Summary
          </span>
          <IconButton size="small" onClick={toggleExpand}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Typography>

        {isExpanded && (
          <>
            {/* If the first part has text, show it as regular typography */}
            {firstPart && (
              <Typography
                variant="body1"
                sx={{ color: theme.palette.text.secondary, marginBottom: 2 }}
              >
                {firstPart}
              </Typography>
            )}

            {/* Render remaining parts as a list */}
            {remainingParts.length > 0 && (
              <List sx={{ padding: 0, listStyle: "none" }}>
                {remainingParts.map((part, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      padding: 0,
                      marginBottom: 1,
                      "&::before": {
                        content: '"•"',
                        color: theme.palette.text.secondary,
                        fontWeight: "bold",
                        marginRight: "8px",
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ color: theme.palette.text.secondary }}
                    >
                      {part.trim()}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

VarianceSummary.propTypes = {
  summary: PropTypes.string.isRequired,
};

export default VarianceSummary;
