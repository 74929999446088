import axios from "axios";
import { API_URL } from "./config";
import { handleExportToWordBlob } from "./viDocxService";
import { saveAs } from "file-saver";

export const handleSaveAsPDF = async (fixtureRecapDetails, organisationId) => {
  const wordDocBlob = await handleExportToWordBlob(
    fixtureRecapDetails,
    organisationId
  );
  const wordFile = new File([wordDocBlob], "Voyage_Instructions.docx");
  const formData = new FormData();
  formData.append("file", wordFile);

  try {
    const response = await axios.post(`${API_URL}/export/word2pdf`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
      responseType: "blob",
    });

    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    saveAs(pdfBlob, "Voyage_Instructions.pdf");
  } catch (error) {
    console.error("Error generating voyage instructions:", error);
    throw error;
  }
};
