export const boldHeaderStyle = {
  fontWeight: 700,
  fontSize: "16px",
  borderBottom: "1px solid #2D6BCB",
};

export const borderStyle = {
  borderBottom: "1px solid #2D6BCB",
};

export const borderBRStyle = {
  borderBottom: "1px solid #2D6BCB",
  borderRight: "1px solid #2D6BCB",
};
