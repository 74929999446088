import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid2,
  Typography,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  toTitleCase,
  handleCopyMRComparisonTable,
  handleSaveExcelMRComparisonTable,
} from "../../helpers";
import VarianceSummary from "../../components/comparison/VarianceSummary";
import ComparisonTable from "../../components/comparison/ComparisonTable";
import ComparisonDetails from "../../components/comparison/ComparisonDetails";
import PropTypes from "prop-types";
import Step1Buttons from "../../components/common/Step1Buttons";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const checkForChanged = (significance) => {
  switch (significance) {
    case "EXACT_MATCH":
      return false;
    case "MATERIAL_VARIANCE":
      return true;
    case "INSIGNIFICANT_VARIANCE":
      return true;
    default:
      return false;
  }
};

const getStyle = (significance) => {
  switch (significance) {
    case "EXACT_MATCH":
      return { color: "inherit" };
    case "MATERIAL_VARIANCE":
      return { color: "#DC3058" };
    case "INSIGNIFICANT_VARIANCE":
      return { color: "#FF8B00" };
    default:
      return { color: "inherit" };
  }
};

const MatesReceiptsResult = ({ response, titles, handleStepChange }) => {
  const [mrComparisonResult, setMrComparisonResult] = useState(null);
  const [filteredRows, setFilteredRows] = useState({ main: [], holds: [] });

  useEffect(() => {
    setMrComparisonResult(response);
  }, [response]);

  if (!mrComparisonResult) return null;

  const { comparisonData } = mrComparisonResult;

  const handleGeneration = () => {
    handleStepChange(2);
  };

  const handleCopy = () => {
    handleCopyMRComparisonTable(filteredRows, mrComparisonResult, titles);
  };

  const handleSavePdf = () => {
    const id = `${response.request_id}mr-comparison-table`;
    const tableElement = document.getElementById(id);

    if (!tableElement) {
      alert("MRComparisonTable not found.");
      return;
    }

    // Use html2canvas to capture the table
    html2canvas(tableElement, { scale: 2 })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const padding = 10;

        // Calculate the PDF dimensions and add padding
        const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * padding;
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        pdf.addImage(imgData, "PNG", padding, padding, pdfWidth, pdfHeight);

        pdf.save("MRComparisonTable.pdf");
      })
      .catch((err) => {
        console.error("Failed to save PDF: ", err);
      });
  };

  const handleSaveExcel = () => {
    handleSaveExcelMRComparisonTable(filteredRows, mrComparisonResult, titles);
  };

  return (
    <Box>
      <VarianceSummary summary={comparisonData?.variance_summary} />
      <ComparisonTable
        onCopy={() => handleCopy}
        onSavePdf={() => handleSavePdf}
        onSaveExcel={() => handleSaveExcel}
        filterOptions={[
          { value: "all", label: "Show All" },
          { value: "discrepancies", label: "Show Only Discrepancies" },
          { value: "missing", label: "Show Only Missing" },
        ]}
      >
        <ComparisonDetails>
          <MRDetails
            details={mrComparisonResult.doc1}
            title={titles[0]}
          ></MRDetails>
          <MRDetails
            details={mrComparisonResult.doc2}
            title={titles[1]}
          ></MRDetails>
        </ComparisonDetails>
        <MRComparisonTable
          comparisonResult={mrComparisonResult}
          filter={"all"}
          filteredRows={filteredRows}
          setFilteredRows={setFilteredRows}
          titles={titles}
        />
      </ComparisonTable>
      {handleStepChange && (
        <Step1Buttons
          title="Proceed To Generation"
          validation={false}
          handleMoveNextStep={handleGeneration}
        ></Step1Buttons>
      )}
    </Box>
  );
};

const MRComparisonTable = ({
  comparisonResult,
  filter,
  filteredRows,
  setFilteredRows,
  titles,
}) => {
  const theme = useTheme();
  const boldHeaderStyle = {
    fontWeight: 700,
    fontSize: "16px",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  };

  useEffect(() => {
    if (!comparisonResult) return;

    const allFields = [
      "short_name",
      "date",
      "mr_id",
      "vessel",
      "port_of_loading",
      "port_of_discharge",
      "shipper",
      "consignee",
      "shipping_marks",
      "shipper_reference",
    ];

    const filteredMain = allFields.filter((field) => {
      const value1 = comparisonResult?.doc1[field];
      const value2 = comparisonResult?.doc2[field];
      const change = comparisonResult?.comparisonData[`${field}_change`] || {
        significant_variance: "EXACT_MATCH",
      };

      if (filter === "all") return true;
      if (filter === "discrepancies")
        return checkForChanged(change.significant_variance);
      if (filter === "missing") return !value1 || !value2;
      return false;
    });

    const holdFields = [
      "hold_id",
      "content_description",
      "quantity_description",
    ];
    const filteredHolds = holdFields.filter((field) => {
      const value1 = comparisonResult?.doc1.hold_details[0][field];
      const value2 = comparisonResult?.doc2.hold_details[0][field];
      const change =
        comparisonResult?.comparisonData.hold_changes[0][`${field}_variance`] ||
        "EXACT_MATCH";

      if (filter === "all") return true;
      if (filter === "discrepancies") return checkForChanged(change);
      if (filter === "missing") return !value1 || !value2;
      return false;
    });

    const doc1QuantityMetrics =
      comparisonResult?.doc1.hold_details[0]["quantity_metrics"];
    const doc2QuantityMetrics =
      comparisonResult?.doc2.hold_details[0]["quantity_metrics"];
    const holdChanges = comparisonResult?.comparisonData.hold_changes[0];
    const allQuantityMetrics = [
      ...(holdChanges?.["quantity_metrics_variance"] || []),
      ...(holdChanges?.["added_quantity_metrics"] || []),
    ];

    const filteredMetrics = allQuantityMetrics.filter((row) => {
      const change = row.variance || "EXACT_MATCH";
      const doc1Metric = doc1QuantityMetrics.find(
        (m) => m.metric?.toLowerCase() === row.metric?.toLowerCase()
      );
      const doc2Metric = doc2QuantityMetrics.find(
        (m) => m.metric?.toLowerCase() === row.metric?.toLowerCase()
      );

      if (filter === "all") return true;
      if (filter === "discrepancies") return checkForChanged(change);
      if (filter === "missing") return !doc1Metric?.value || !doc2Metric?.value;
      return false;
    });

    setFilteredRows({
      main: filteredMain,
      holds: filteredHolds,
      metrics: filteredMetrics,
    });
  }, [filter, comparisonResult, setFilteredRows]);

  return (
    <Box id={`${comparisonResult.request_id}mr-comparison-table`}>
      <TableContainer
        style={{
          backgroundColor: theme.palette.background.input,
          maxHeight: 800,
          overflowY: "auto",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            {filteredRows.main?.length > 0 && (
              <TableRow>
                <TableCell style={boldHeaderStyle}></TableCell>
                <TableCell style={boldHeaderStyle}>
                  {titles[0] || "Original MR"}
                </TableCell>
                <TableCell style={boldHeaderStyle}>
                  {titles[1] || "Updated MR"}
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {filteredRows.main?.map((field, index) =>
              renderMRDetails(
                comparisonResult.doc1,
                comparisonResult.doc2,
                comparisonResult.comparisonData,
                field,
                index
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(filteredRows.holds?.length > 0 || filteredRows.metrics?.length > 0) && (
        <Typography variant="h4" sx={{ fontWeight: 600, my: 2 }}>
          Hold Details
        </Typography>
      )}
      <TableContainer
        style={{
          backgroundColor: theme.palette.background.input,
          maxHeight: 800,
          overflowY: "auto",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            {filteredRows.holds?.length > 0 && (
              <TableRow>
                <TableCell style={boldHeaderStyle}></TableCell>
                <TableCell style={boldHeaderStyle}>
                  {titles[0] || "Original MR"}
                </TableCell>
                <TableCell style={boldHeaderStyle}>
                  {titles[1] || "Updated MR"}
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {filteredRows.holds?.map((field, index) =>
              renderHoldDetails(
                comparisonResult.doc1,
                comparisonResult.doc2,
                comparisonResult.comparisonData,
                field,
                index
              )
            )}
            {filteredRows.metrics?.length > 0 && (
              <TableRow>
                <TableCell
                  style={{
                    borderRight: "1px solid #3D86F6",
                    ...boldHeaderStyle,
                  }}
                >
                  QUANTITY METRICS
                </TableCell>
                <TableCell style={boldHeaderStyle} />
                <TableCell style={boldHeaderStyle} />
              </TableRow>
            )}
            {filteredRows.metrics?.map((row, index) =>
              renderHoldDetailsQuantityMetrics(
                comparisonResult.doc1,
                comparisonResult.doc2,
                row,
                index
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
MRComparisonTable.propTypes = {
  comparisonResult: PropTypes.object.isRequired,
  filter: PropTypes.string.isRequired,
  filteredRows: PropTypes.object.isRequired,
  setFilteredRows: PropTypes.func.isRequired,
  titles: PropTypes.array,
};

const MRDetails = ({ details, title }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card
      sx={{
        background: theme.palette.background.level2,
        borderRadius: "8px",
        boxShadow: "0px 4px 8px 0px rgba(38, 40, 44, 0.08)",
      }}
    >
      <CardContent>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: 500,
            marginBottom: isExpanded ? 1 : 0,
            borderBottom: "1px solid #3D86F6",
          }}
        >
          {title}
          <IconButton size="small" onClick={toggleExpand}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Typography>
        {isExpanded && (
          <Box>
            <Grid2 container spacing={2}>
              <Grid2 size={4}>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.blue3 }}
                >
                  Short Name
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.blue3 }}
                >
                  Date
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.blue3 }}
                >
                  MR ID
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.blue3 }}
                >
                  Vessel
                </Typography>
              </Grid2>
              <Grid2 size={8}>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.main }}
                >
                  {details.short_name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.main }}
                >
                  {details.date}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.main }}
                >
                  {details.mr_id || "-"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.main }}
                >
                  {details.vessel}
                </Typography>
              </Grid2>
            </Grid2>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

MRDetails.propTypes = {
  details: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

const renderMRDetails = (doc1, doc2, comparison, field, index) => {
  const value1 = doc1[field];
  const value2 = doc2[field];
  const change = comparison[`${field}_change`] || {
    changed: false,
    significant_variance: "EXACT_MATCH",
  };

  if (
    (field === "shipper_reference" || field === "mr_id") &&
    !value1 &&
    !value2
  )
    return null;

  return (
    <TableRow key={`${field}-${index}`}>
      <TableCell
        style={getStyle(change.significant_variance)}
        sx={{ borderRight: "1px solid #3D86F6" }}
      >
        {toTitleCase(field)}
        {change.significance_reasoning && (
          <Tooltip title={change.significance_reasoning}>
            <IconButton sx={{ color: "gray", opacity: 0.5 }}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <TableCell style={getStyle(change.significant_variance)}>
        {value1 || "-"}
      </TableCell>
      <TableCell style={getStyle(change.significant_variance)}>
        {value2 || "-"}
      </TableCell>
    </TableRow>
  );
};

const renderHoldDetails = (doc1, doc2, comparison, field, index) => {
  const value1 = doc1.hold_details[0][field];
  const value2 = doc2.hold_details[0][field];
  const change =
    comparison.hold_changes[0][`${field}_variance`] || "EXACT_MATCH";
  const significanceReasoning =
    comparison.hold_changes[0].significance_reasoning;
  return (
    <TableRow key={`hold-${index}`}>
      <TableCell
        style={getStyle(change)}
        sx={{ borderRight: "1px solid #3D86F6" }}
      >
        {toTitleCase(field)}
        {change && (
          <Tooltip title={significanceReasoning}>
            <IconButton sx={{ color: "gray", opacity: 0.5 }}>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <TableCell style={getStyle(change)}>{value1}</TableCell>
      <TableCell style={getStyle(change)}>{value2}</TableCell>
    </TableRow>
  );
};

const renderHoldDetailsQuantityMetrics = (doc1, doc2, row, index) => {
  const doc1QuantityMetrics = doc1.hold_details[0]["quantity_metrics"];
  const doc2QuantityMetrics = doc2.hold_details[0]["quantity_metrics"];
  const doc1Metric = doc1QuantityMetrics.find(
    (m) => m.metric?.toLowerCase() === row.metric?.toLowerCase()
  );
  const doc2Metric = doc2QuantityMetrics.find(
    (m) => m.metric?.toLowerCase() === row.metric?.toLowerCase()
  );
  const change = row.variance || "MATERIAL_VARIANCE";

  return (
    <TableRow key={`hold-quantity-metrics-${index}`}>
      <TableCell
        style={getStyle(change)}
        sx={{ borderRight: "1px solid #3D86F6" }}
      >
        {toTitleCase(row.metric)}
      </TableCell>
      <TableCell style={getStyle(change)}>{doc1Metric?.value || "-"}</TableCell>
      <TableCell style={getStyle(change)}>{doc2Metric?.value || "-"}</TableCell>
    </TableRow>
  );
};

MatesReceiptsResult.propTypes = {
  response: PropTypes.object.isRequired,
  titles: PropTypes.array,
  handleStepChange: PropTypes.func,
};
export default MatesReceiptsResult;
