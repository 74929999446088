import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  CardContent,
  IconButton,
  Box,
  Collapse,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const ShipsterCard = ({ children, title, level }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getBackgroundColor = () => {
    switch (level) {
      case 0:
        return theme.palette.background.default;
      case 1:
        return theme.palette.background.level1;
      case 2:
        return theme.palette.background.level2;
      case 3:
        return theme.palette.background.level3;
      default:
        return theme.palette.background.default;
    }
  };

  // Normalize children into an array
  const childrenArray = React.Children.toArray(children);

  return (
    <Card
      sx={{
        backgroundColor: getBackgroundColor(),
        color: theme.palette.text.primary,
        borderRadius: 3,
        boxShadow: "0px 4px 8px 0px rgba(38, 40, 44, 0.08)",
        marginTop: 2,
        marginBottom: 4,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography variant="intro" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <IconButton
          onClick={toggleExpand}
          sx={{ color: theme.palette.text.primary }}
        >
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      {/* Card Content */}
      <Collapse in={isExpanded}>
        <CardContent>
          {childrenArray.map((child, index) => (
            <Box key={index}>{React.cloneElement(child)}</Box>
          ))}
        </CardContent>
      </Collapse>
    </Card>
  );
};

ShipsterCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
};

export default ShipsterCard;
