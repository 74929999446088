import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Switch,
  Collapse,
  Divider,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PropTypes from "prop-types";
import MatesReceiptsResult from "./MatesReceiptsResult";
import MatchPages from "../../components/matching/MatchPages";
import Step1Buttons from "../../components/common/Step1Buttons";

const MultipleMRBLResult = ({ response, comparedWithBl, handleStepChange }) => {
  const [result, setResult] = useState(null);
  const [compiledResponses, setCompiledResponses] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState({});
  const [includedComparisons, setIncludedComparisons] = useState({});
  const theme = useTheme();

  useEffect(() => {
    setResult(response);
  }, [response]);

  if (!result) return null;

  const { matches } = result;

  const handleMatchConfirmation = (response) => {
    setCompiledResponses((prevResponses) => [...prevResponses, response]);
  };

  const handleMatchRemoval = (docId) => {
    setCompiledResponses((prevResponses) =>
      prevResponses.filter((res) => {
        const docToCheck = comparedWithBl
          ? res.comparison_result.mates_receipt.doc_id
          : res.comparison_result.original_MR.doc_id;
        return docToCheck !== docId;
      })
    );
  };

  const togglePanel = (index) => {
    setExpandedPanels((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleSwitchChange = (index) => {
    setIncludedComparisons((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleGeneration = () => {
    const filteredResponses = compiledResponses.filter(
      (_, index) => includedComparisons[index]
    );
    handleStepChange(2, filteredResponses);
  };

  const generationDisabled = () => {
    // Check if the object is empty
    if (Object.keys(includedComparisons).length === 0) {
      return true;
    }

    // Check if all values are false
    const allFalse = Object.values(includedComparisons).every(
      (value) => !value
    );
    return allFalse;
  };

  const renderPanelContent = (response, index) => {
    const { comparison_result: comparison } = response;
    const titles = comparedWithBl
      ? ["Mates Receipt", "Bill of Lading"]
      : ["Original Mates Receipt", "Updated Mates Receipt"];
    const doc1 = comparedWithBl
      ? comparison.mates_receipt
      : comparison.original_MR;
    const doc2 = comparedWithBl
      ? comparison.bill_of_lading
      : comparison.updated_MR;
    const comparisonData = comparedWithBl
      ? comparison.mr_bl_comparison
      : comparison.mr_comparison;
    const request_id = response.request_id;
    const data = { doc1, doc2, comparisonData, request_id };

    return (
      <Card
        sx={{
          background: theme.palette.background.level1,
          my: 2,
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Typography variant="intro" sx={{ fontWeight: 600 }}>
            Comparison Results{" "}
            {comparedWithBl
              ? response.comparison_result.mates_receipt.short_name
              : response.comparison_result.updated_MR.short_name}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              sx={{ mr: 1, color: theme.palette.primary.blue3 }}
            >
              {expandedPanels[index] ? "Hide Results" : "Show Results"}
            </Typography>
            <IconButton
              onClick={() => togglePanel(index)}
              sx={{ color: theme.palette.primary.blue3 }}
            >
              {expandedPanels[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </Box>
        {!comparedWithBl && (
          <Box sx={{ px: 2, pb: 2, display: "flex", alignItems: "center" }}>
            <Switch onChange={() => handleSwitchChange(index)} />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Include in generation
            </Typography>
          </Box>
        )}

        <Collapse in={expandedPanels[index]}>
          <CardContent>
            <MatesReceiptsResult response={data} titles={titles} />
          </CardContent>
        </Collapse>
      </Card>
    );
  };

  return (
    <Box>
      <MatchPages
        files1={comparedWithBl ? result.mates_receipts : result.mates_receipt1}
        files2={comparedWithBl ? result.bills_of_lading : result.mates_receipt2}
        matchData={matches.matches}
        onMatchConfirmed={handleMatchConfirmation}
        onMatchRemoved={handleMatchRemoval}
        comparedWithBl={comparedWithBl}
      />

      <Divider sx={{ my: 2 }} />

      {compiledResponses.map((response, index) => (
        <Box key={`panel-${index}`} mb={2}>
          {renderPanelContent(response, index)}
        </Box>
      ))}
      {!comparedWithBl && (
        <Step1Buttons
          title="Proceed To Generation"
          validation={generationDisabled()}
          handleMoveNextStep={handleGeneration}
        />
      )}
    </Box>
  );
};

MultipleMRBLResult.propTypes = {
  response: PropTypes.object.isRequired,
  handleStepChange: PropTypes.func,
  comparedWithBl: PropTypes.bool,
};

export default MultipleMRBLResult;
