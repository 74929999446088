export const getNavigatorBendingCargoLOITemplate = ({
  shipper,
  consignee,
  requestorParty,
  portDischarge,
  portLoading,
  cargo,
}) => {
  return {
    templateLabel: "Blending Cargo",

    indemnityTerms:
      "In consideration of your complying with our above request, we hereby agree as follows:",

    indemnityAgreement: [
      "1. To indemnify you, your servants and agents and to hold all of you harmless in respect of any liability, loss, damage or expense of whatsoever nature (including but not limited to claims for contamination or quality deterioration, failure to meet any contractual specification, damage to ship and equipment, damage to third parties and legal expenses) howsoever caused and in any way arising from the commingling operation or charterers' instructions relating thereto.",
      "2. In the event of any proceedings being commenced against you or any of your servants or agents in connection with the commingling operation and its consequences as aforesaid, to provide you or them on demand with sufficient funds to defend the same.",
      "3. If, in connection with the commingling operation and its consequences as aforesaid, the ship, or any other ship or property in the same or associated ownership, management or control, should be arrested or detained or should the arrest or detention thereof be threatened, or should there be any interference in the use or trading of the vessel (whether by virtue of a caveat being entered on the ship’s registry or otherwise howsoever), to provide on demand such bail or other security as may be required to prevent such arrest or detention or to secure the release of such ship or property or to remove such interference and to indemnify you in respect of any liability, loss, damage or expense caused by such arrest or detention or threatened arrest or detention or such interference, whether or not such arrest or detention or threatened arrest or detention or such interference may be justified.",
      "4. The liability of each and every person under this indemnity shall be joint and several and shall not be conditional upon your proceeding first against any person, whether or not such person is party to or liable under this indemnity.",
      "5. This indemnity shall be governed by and construed in accordance with English law and each and every person liable under this indemnity shall at your request submit to the jurisdiction of the High Court of Justice of England.",
    ],

    indemnitySublist: [],

    signatureText: `
            Yours faithfully,
            For and on behalf of
            [insert name of Requestor]
            The Requestor


            .......................................
            Signature
    `,
    word_textBeforeIndemnityList: `
        The above cargo was shipped on the above vessel by ${shipper} and consigned
        to ${consignee} for delivery at the port of ${portDischarge}.

        We, ${requestorParty}, hereby request you to proceed to ${portLoading}, and [delete as appropriate] commingle / blend ${cargo} with the cargo described above and presently on board CT# "MISSING", for discharge at ${portDischarge}.

        In consideration of your complying with our above request, we hereby agree as follows :-

        `,

    word_indemnityAgreementList: `
            To indemnify you, your servants and agents and to hold all of you harmless in respect of any liability, loss, damage or expense of whatsoever nature (including but not limited to claims for contamination or quality deterioration, failure to meet any contractual specification, damage to ship and equipment, damage to third parties and legal expenses) howsoever caused and in any way arising from the commingling operation or charterers' instructions relating thereto.
            In the event of any proceedings being commenced against you or any of your servants or agents in connection with the commingling operation and its consequences as aforesaid, to provide you or them on demand with sufficient funds to defend the same.
            If, in connection with the commingling operation and its consequences as aforesaid, the ship, or any other ship or property in the same or associated ownership, management or control, should be arrested or detained or should the arrest or detention thereof be threatened, or should there be any interference in the use or trading of the vessel (whether by virtue of a caveat being entered on the ship’s registry or otherwise howsoever), to provide on demand such bail or other security as may be required to prevent such arrest or detention or to secure the release of such ship or property or to remove such interference and to indemnify you in respect of any liability, loss, damage or expense caused by such arrest or detention or threatened arrest or detention or such interference, whether or not such arrest or detention or threatened arrest or detention or such interference may be justified.
            The liability of each and every person under this indemnity shall be joint and several and shall not be conditional upon your proceeding first against any person, whether or not such person is party to or liable under this indemnity.
            This indemnity shall be governed by and construed in accordance with English law and each and every person liable under this indemnity shall at your request submit to the jurisdiction of the High Court of Justice of England.
        `,
  };
};
