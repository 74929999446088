export const applyGlobalStyles = (styles) => {
  for (const selector in styles) {
    const styleRules = styles[selector];
    const elements = document.querySelectorAll(selector);

    elements.forEach((element) => {
      Object.assign(element.style, styleRules);
    });
  }
};
