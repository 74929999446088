import React from "react";
import PropTypes from "prop-types";
import { Grid2, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Step1Buttons = ({ title, validation, handleMoveNextStep }) => {
  const navigate = useNavigate();

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={2} key={1}>
        <Button
          onClick={() => {
            navigate("/dashboard");
          }}
          variant="outlined"
          sx={{
            mt: 3,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Cancel
        </Button>
      </Grid2>
      <Grid2 size={10} key={2}>
        <Button
          onClick={handleMoveNextStep}
          variant="contained"
          sx={{
            mt: 3,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          disabled={validation}
        >
          {title}
        </Button>
      </Grid2>
    </Grid2>
  );
};

Step1Buttons.propTypes = {
  title: PropTypes.string.isRequired,
  validation: PropTypes.bool.isRequired,
  handleMoveNextStep: PropTypes.func.isRequired,
};

export default Step1Buttons;
