import React, { useState } from "react";
import { Box, Alert, Typography, IconButton, useTheme } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import PDFViewer from "./PDFViewer";
import PropTypes from "prop-types";

const DocumentUploader = ({
  files,
  setFiles,
  title,
  setResult,
  allowedFileTypes = ["pdf", "docx", "xlsx"],
  singleFile = false,
}) => {
  const [error, setError] = useState(null);
  const theme = useTheme();

  // Create the accept object dynamically based on allowedFileTypes
  const fileTypeMapping = {
    pdf: "application/pdf",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xlsx: "application/vnd.ms-excel",
  };

  const accept = Object.fromEntries(
    allowedFileTypes.map((type) => [fileTypeMapping[type], [`.${type}`]])
  );

  const deleteFile = (file, section, setFiles) => {
    const updatedFiles = Array.isArray(section)
      ? section.filter((f) => f.name !== file.name)
      : null;
    setFiles(updatedFiles);
    setResult(null);
  };

  const handleDropSection = (acceptedFiles) => {
    setError(null);

    if (singleFile) {
      // Allow only a single file
      setFiles(acceptedFiles[0] || null);
    } else {
      // Allow multiple files
      setFiles([...files, ...acceptedFiles]);
    }
  };

  const handleDropRejected = (fileRejections) => {
    // Dynamically generate allowed file types message
    const allowedTypesMessage = `Please upload ${allowedFileTypes
      .map((type) => type.toUpperCase())
      .join(", ")} file.`;

    const errorMessage = fileRejections
      .map((rejection) =>
        rejection.errors.map((error) => error.message).join(", ")
      )
      .join("; ");

    setError(`This file type is not supported. ${allowedTypesMessage}`);
    console.log(errorMessage);
  };

  const {
    getRootProps: getRootPropsSection,
    getInputProps: getInputPropsSection,
  } = useDropzone({
    onDrop: handleDropSection,
    onDropRejected: handleDropRejected,
    accept,
    maxFiles: singleFile ? 1 : Infinity,
  });

  // Normalize `files` to an array for consistent rendering
  const normalizedFiles = Array.isArray(files) ? files : files ? [files] : [];

  return (
    <>
      {/* Error Alert */}
      {error && (
        <Box
          sx={{
            position: "fixed",
            top: 106,
            right: 16,
            zIndex: 1000,
            width: "auto",
            maxWidth: "500px",
          }}
        >
          <Alert
            severity="error"
            variant="outlined"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setError(null)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {error}
          </Alert>
        </Box>
      )}
      <Box>
        <Typography variant="body1">{title}</Typography>
        <Box
          {...getRootPropsSection()}
          sx={{
            border: "1px dashed #B4B9C3",
            padding: 3,
            textAlign: "center",
            my: 2,
            borderRadius: 2,
            cursor: "pointer",
            background: theme.palette.background.default,
          }}
        >
          <input {...getInputPropsSection()} />
          <CloudUploadIcon
            sx={{ fontSize: "3rem", color: theme.palette.text.secondary }}
          />
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.secondary }}
          >
            Drag & Drop or click for upload
          </Typography>
        </Box>
        {/* Display Uploaded Files */}
        {normalizedFiles.map((file, index) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 1,
              "&:hover": {
                backgroundColor: theme.palette.background.level2,
              },
            }}
            key={`document: ${index}`}
          >
            {/* File name and icon */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <InsertDriveFileOutlinedIcon
                sx={{
                  color: theme.palette.primary.main,
                }}
              />
              <Typography
                variant="body1"
                sx={{ color: theme.palette.primary.main }}
              >
                {file.name}
              </Typography>
            </Box>

            {/* Delete button */}
            <IconButton
              onClick={() => deleteFile(file, normalizedFiles, setFiles)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
      {/* PDF Viewer Section */}
      {files && <PDFViewer file={files} />}
    </>
  );
};

DocumentUploader.propTypes = {
  files: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setFiles: PropTypes.func.isRequired,
  title: PropTypes.string,
  setResult: PropTypes.func.isRequired,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.oneOf(["pdf", "docx", "xlsx"]))
    .isRequired,
  singleFile: PropTypes.bool.isRequired,
};

export default DocumentUploader;
