import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid2,
  useTheme,
} from "@mui/material";
import {
  borderBRStyle,
  borderStyle,
  boldHeaderStyle,
} from "../../styles/comparisonTableStyles";
import PropTypes from "prop-types";
import VarianceSummary from "../../components/comparison/VarianceSummary";
import ComparisonTable from "../../components/comparison/ComparisonTable";
import ComparisonDetails from "../../components/comparison/ComparisonDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { exportToExcelCargo, copyToClipboardCargo } from "../../helpers";

const CargoResult = ({ response }) => {
  const [cargoComparisonResult, setCargoComparisonResult] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    setCargoComparisonResult(response);
  }, [response]);

  if (!cargoComparisonResult) return null;
  const { comparison_result } = cargoComparisonResult;

  // eslint-disable-next-line no-unused-vars
  const handleCopy = (filter) => () => {
    copyToClipboardCargo(filteredRows, comparison_result);
  };
  // eslint-disable-next-line no-unused-vars
  const handleSavePdf = (filter) => () => {
    alert("Save as PDF still in development!");
  };
  // eslint-disable-next-line no-unused-vars
  const handleSaveExcel = (filter) => () => {
    exportToExcelCargo(filteredRows, comparison_result);
  };

  return (
    <Box>
      <VarianceSummary
        summary={comparison_result.cargo_comparison.variance_summary}
      />
      <ComparisonTable
        onCopy={handleCopy}
        onSavePdf={handleSavePdf}
        onSaveExcel={handleSaveExcel}
        filterOptions={[
          { value: "all", label: "Show All" },
          { value: "discrepancies", label: "Show Only Discrepancies" },
          { value: "missing", label: "Show Only Missing" },
        ]}
      >
        <ComparisonDetails>
          <CargoDetails
            details={comparison_result.second_set_of_documents}
          ></CargoDetails>
          <CargoDetails
            details={comparison_result.first_set_of_documents}
          ></CargoDetails>
        </ComparisonDetails>
        <CargoTable
          comparisonResult={comparison_result}
          filter={"all"}
          filteredRows={filteredRows}
          setFilteredRows={setFilteredRows}
        ></CargoTable>
      </ComparisonTable>
    </Box>
  );
};

CargoResult.propTypes = {
  response: PropTypes.object.isRequired, // Define the type of response, e.g., object, string, etc.
};

const CargoTable = ({
  comparisonResult,
  filter,
  filteredRows,
  setFilteredRows,
}) => {
  const theme = useTheme();

  useEffect(() => {
    const applyFilter = () => {
      return comparisonResult.cargo_comparison.lot_comparisons.filter((row) => {
        const voyageManifestRow = allVMLotDetails.find(
          (vm) => vm.lot === row.lot
        );
        const billLadingRow = allBLLotDetails.find((bl) => bl.lot === row.lot);

        const missing = !voyageManifestRow || !billLadingRow;

        const hasChangedVariance = row.quantity_metrics_variance?.some((m) => {
          const variance = m.variance;
          return checkForChanged(variance);
        });

        if (filter === "discrepancies") {
          return hasChangedVariance;
        } else if (filter === "missing") {
          return missing;
        }
        return true; // Default: show all
      });
    };
    // Combine all lot details from all voyage manifests
    const allVMLotDetails = comparisonResult.second_set_of_documents.flatMap(
      (doc) => doc.lot_details
    );
    // Combine all lot details from all documents
    const allBLLotDetails = comparisonResult.first_set_of_documents.flatMap(
      (doc) => doc.lot_details
    );

    setFilteredRows(applyFilter());
  }, [comparisonResult, filter, setFilteredRows]);

  // Combine all lot details from all voyage manifests
  const allVMLotDetails = comparisonResult.second_set_of_documents.flatMap(
    (doc) => doc.lot_details
  );
  // Combine all lot details from all documents
  const allBLLotDetails = comparisonResult.first_set_of_documents.flatMap(
    (doc) => doc.lot_details
  );

  const checkForChanged = (significance) => {
    return significance === "MATERIAL_VARIANCE";
  };

  return (
    <TableContainer
      style={{
        backgroundColor: theme.palette.background.input,
        maxHeight: 800,
        overflowY: "auto",
      }}
    >
      <Table stickyHeader>
        <TableHead sx={{ position: "sticky", top: 0, zIndex: 1 }}>
          <TableRow>
            <TableCell style={{ ...boldHeaderStyle }} />
            <TableCell
              style={{
                ...boldHeaderStyle,
                textAlign: "center",
              }}
              colSpan={5}
            >
              {comparisonResult.second_set_of_documents[0].short_name}
            </TableCell>

            <TableCell style={{ ...boldHeaderStyle }} />

            <TableCell
              style={{
                ...boldHeaderStyle,
                textAlign: "center",
              }}
              colSpan={5}
            >
              {comparisonResult.first_set_of_documents[0].short_name}
            </TableCell>

            <TableCell style={{ ...boldHeaderStyle }} />
          </TableRow>
          <TableRow>
            <TableCell style={borderBRStyle}>LOT</TableCell>
            {comparisonResult.second_set_of_documents[0].lot_details[0].quantity_metrics.map(
              (metric) => {
                return (
                  <TableCell style={borderStyle} key={metric.metric}>
                    {metric.metric}
                  </TableCell>
                );
              }
            )}
            <TableCell style={borderStyle}>Description</TableCell>
            <TableCell style={borderStyle}>Port of Loading</TableCell>
            <TableCell style={borderStyle}>Port of Discharge</TableCell>
            <TableCell style={borderBRStyle}>Page</TableCell>
            {comparisonResult.first_set_of_documents[0].lot_details[0].quantity_metrics.map(
              (metric) => {
                return (
                  <TableCell
                    style={borderStyle}
                    key={`table cell: ${metric.metric}`}
                  >
                    {metric.metric}
                  </TableCell>
                );
              }
            )}
            <TableCell style={borderStyle}>Description</TableCell>
            <TableCell style={borderStyle}>Port of Loading</TableCell>
            <TableCell style={borderStyle}>Port of Discharge</TableCell>
            <TableCell style={borderStyle}>Page</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows.map((lot, index) => {
            const voyageManifestRow = allVMLotDetails.find(
              (vm) => vm.lot === lot.lot
            );
            const billLadingRow = allBLLotDetails.find(
              (bl) => bl.lot === lot.lot
            );

            const quantityMetricsVM = voyageManifestRow?.quantity_metrics || [];
            const quantityMetricsBL = billLadingRow?.quantity_metrics || [];

            const missing = !voyageManifestRow || !billLadingRow;
            const hasVariance =
              lot.quantity_metrics_variance?.some(
                (m) =>
                  quantityMetricsVM.some(
                    (metric) =>
                      m.metric === metric.metric ||
                      (Array.isArray(m.metric_synonyms) &&
                        m.metric_synonyms.includes(metric.metric))
                  ) && checkForChanged(m.variance)
              ) ||
              lot.quantity_metrics_variance?.some(
                (m) =>
                  quantityMetricsBL.some(
                    (metric) =>
                      m.metric === metric.metric ||
                      (Array.isArray(m.metric_synonyms) &&
                        m.metric_synonyms.includes(metric.metric))
                  ) && checkForChanged(m.variance)
              );

            let textColor = "inherit";
            // Changes color of text based on severity incase of overlap between missing and variance , missing is prioritized
            if (missing) {
              textColor = theme.palette.semantic.error;
            } else if (hasVariance) {
              textColor = theme.palette.semantic.warning;
            }

            return (
              <TableRow key={`row: ${index}`}>
                <TableCell
                  style={{
                    color: textColor,
                    borderRight: `2px solid ${theme.palette.primary.main}`,
                  }}
                >
                  {lot.lot}
                </TableCell>
                {quantityMetricsVM.length === 0
                  ? Array.from({
                      length:
                        comparisonResult.second_set_of_documents[0]
                          .lot_details[0].quantity_metrics.length,
                    }).map((_, i) => (
                      <TableCell
                        key={`vm-missing-${i}`}
                        style={{
                          color: textColor,
                        }}
                      >
                        -
                      </TableCell>
                    ))
                  : quantityMetricsVM.map((metric, metricIndex) => (
                      <TableCell
                        key={`metric-${metric.metric}-${metricIndex}`}
                        style={{
                          color: textColor,
                        }}
                      >
                        {metric.value}
                      </TableCell>
                    ))}
                <TableCell style={{ color: textColor }}>
                  {voyageManifestRow?.description || "-"}
                </TableCell>
                <TableCell style={{ color: textColor }}>
                  {voyageManifestRow?.port_of_loading || "-"}
                </TableCell>
                <TableCell style={{ color: textColor }}>
                  {voyageManifestRow?.port_of_discharge || "-"}
                </TableCell>
                <TableCell
                  style={{
                    color: textColor,
                    borderRight: `2px solid ${theme.palette.primary.main}`,
                  }}
                >
                  {voyageManifestRow?.page || "-"}
                </TableCell>
                {quantityMetricsBL.length === 0
                  ? Array.from({
                      length:
                        comparisonResult.first_set_of_documents[0]
                          .lot_details[0].quantity_metrics.length,
                    }).map((_, i) => (
                      <TableCell
                        key={`bl-missing-${i}`}
                        style={{
                          color: textColor,
                        }}
                      >
                        -
                      </TableCell>
                    ))
                  : quantityMetricsBL.map((metric, metricIndex) => {
                      return (
                        <TableCell
                          key={`metric-${metric.metric}-${metricIndex}`}
                          style={{
                            color: textColor,
                          }}
                        >
                          {metric.value}
                        </TableCell>
                      );
                    })}
                <TableCell style={{ color: textColor }}>
                  {billLadingRow?.description || "-"}
                </TableCell>
                <TableCell style={{ color: textColor }}>
                  {billLadingRow?.port_of_loading || "-"}
                </TableCell>
                <TableCell style={{ color: textColor }}>
                  {billLadingRow?.port_of_discharge || "-"}
                </TableCell>
                <TableCell style={{ color: textColor }}>
                  {billLadingRow?.page || "-"}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
CargoTable.propTypes = {
  comparisonResult: PropTypes.object.isRequired,
  filter: PropTypes.string.isRequired,
  filteredRows: PropTypes.array.isRequired,
  setFilteredRows: PropTypes.func.isRequired,
};

const CargoDetails = ({ details }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card
      sx={{
        background: theme.palette.background.level2,
        borderRadius: "8px",
        boxShadow: "0px 4px 8px 0px rgba(38, 40, 44, 0.08)",
      }}
    >
      <CardContent>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: 500,
            marginBottom: isExpanded ? 1 : 0,
            borderBottom: "1px solid #3D86F6",
          }}
        >
          {details[0].short_name}
          <IconButton size="small" onClick={toggleExpand}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Typography>
        {isExpanded && (
          <Box>
            <Grid2 container spacing={2}>
              <Grid2 size={4}>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.blue3 }}
                >
                  Vessel
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.primary.blue3 }}
                >
                  Date
                </Typography>
              </Grid2>
              <Grid2 size={8}>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.main }}
                >
                  {details[0].vessel}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.main }}
                >
                  {details[0].date}
                </Typography>
              </Grid2>
            </Grid2>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

CargoDetails.propTypes = {
  details: PropTypes.array.isRequired, // Define the type of response, e.g., object, string, etc.
};

export default CargoResult;
