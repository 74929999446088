import React from "react";
import { Box, Typography } from "@mui/material";
import { contentStyle } from "../styles/templateStyles";

export const getFalconVITemplate = ({
  cargo,
  cpDate,
  dischargeAgents,
  dischargeAgentEmail,
  laycan,
  loadAgentEmail,
  loadingAgents,
  missingValue,
  portOfDischarge,
  portOfLoading,
  speed,
}) => {
  return {
    text1: `
            Dear Captain, 

            Pls see following voyage instructions for the voyage under Falcon charter. 

            CP dtd: ${cpDate}

            Loadport: ${portOfLoading}

            Disport: ${portOfDischarge}

            Pls provide pre stowage plan accordingly. 

            Cargo: ${cargo}

            Laycan: ${laycan}

            Speed: ${speed}
            Pls perform this voyage on ECO speed (about 18.0 mt vlsfo/24hr day + about 0.20 mt/day mgo), unless otherwise instructed from here. 

            Bunkers: Reverting 

            Holds:  Pls ensure that all holds are clean and dry and in every respect ready to load intended/all permissible cargo 

            N.O.R.: Pls ensure to have the NOR tendered on vsls arrival at the nominated berth. Should berth be occupied on arrival or berthing delayed please tender NOR. On arrival pilot station and re-tender on vsls berthing. NOR to be tendered within office hours, before 1600 hrs lt if possible. 

            B/L:  Shippers require bs/l to be marked ''clean on board'' wherefore pls reject any cargo for which you can't sign clean on board 

            Should chrts require bs/l be marked "freight prepaid" pls instruct agent not to release bs/l before being authorized from this office. 

            Cargo quantity stipulated in b/l must be actual quantity loaded 

            Agent ${missingValue}: 
            ${loadingAgents}
            Phone: ${missingValue} 
            Email: ${loadAgentEmail} 

            Agent ${missingValue}: 
            ${dischargeAgents}
            Tel.: ${missingValue} 
            Mail: ${dischargeAgentEmail} 

            Notices:   
            Please send 7/5/3/2/1 days notices of eta to loadport agents. 
            On sailing loadport, please send daily updates to dischport agents, stating quantity loaded and best eta disport.   

            Kindly confirm safe receipt. 

            Thanks in advance. 

            Best regards, 
            `,
  };
};

export const getFilledFalconVITemplate = ({
  cargo,
  cpDate,
  dischargeAgents,
  dischargeAgentEmail,
  laycan,
  loadAgentEmail,
  loadingAgents,
  missingValue,
  portOfDischarge,
  portOfLoading,
  speed,
}) => {
  return (
    <Box sx={contentStyle} id="text-content">
      <Typography sx={{ mt: 2 }} variant="body1">
        Dear Captain,
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Pls see following voyage instructions for the voyage under Falcon
        charter.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        CP dtd: &nbsp;
        {cpDate}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Loadport: &nbsp;
        {portOfLoading}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Disport: &nbsp;
        {portOfDischarge}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Pls provide pre stowage plan accordingly.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Cargo: &nbsp;
        {cargo}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Laycan: &nbsp;
        {laycan}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Speed: : &nbsp;
        {speed}
      </Typography>
      <Typography variant="body1">
        Pls perform this voyage on ECO speed (about 18.0 mt vlsfo/24hr day +
        about 0.20 mt/day mgo), unless otherwise instructed from here.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Bunkers: &nbsp;
        {missingValue}
      </Typography>

      <Typography sx={{ mt: 2 }} variant="body1">
        Holds: Pls ensure that all holds are clean and dry and in every respect
        ready to load intended/all permissible cargo.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        N.O.R.: Pls ensure to have the NOR tendered on vsls arrival at the
        nominated berth. Should berth be occupied on arrival or berthing delayed
        please tender NOR. On arrival pilot station and re-tender on vsls
        berthing. NOR to be tendered within office hours, before 1600 hrs lt if
        possible.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        B/L: Shippers require bs/l to be marked &apos;&apos;clean on
        board&apos;&apos; wherefore pls reject any cargo for which you
        can&apos;t sign clean on board.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Should chrts require bs/l be marked &quot;freight prepaid,&quot; pls
        instruct agent not to release bs/l before being authorized from this
        office.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Cargo quantity stipulated in b/l must be actual quantity loaded.
      </Typography>

      <Typography sx={{ mt: 2 }} variant="body1">
        Agent {missingValue}:
      </Typography>
      <Typography variant="body1">{loadingAgents}</Typography>
      <Typography variant="body1">Phone: {missingValue}</Typography>
      <Typography variant="body1">Email: {loadAgentEmail}</Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Agent {missingValue}:
      </Typography>
      <Typography variant="body1">{dischargeAgents}</Typography>
      <Typography variant="body1">
        Tel.: &nbsp;
        {missingValue}
      </Typography>
      <Typography variant="body1">
        Mail: &nbsp;
        {dischargeAgentEmail}
      </Typography>

      <Typography sx={{ mt: 2 }} variant="body1">
        Notices: Please send 7/5/3/2/1 days notices of ETA to loadport agents.
      </Typography>
      <Typography variant="body1">
        On sailing loadport, please send daily updates to dischport agents,
        stating quantity loaded and best ETA disport.
      </Typography>
      <Typography sx={{ mt: 3 }} variant="body1">
        Kindly confirm safe receipt.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Thanks in advance.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Best regards,
      </Typography>
    </Box>
  );
};
