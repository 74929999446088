import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Button,
  LinearProgress,
  Collapse,
  Stack,
  Tabs,
  Tab,
  Chip,
  Alert,
  useTheme,
} from "@mui/material";
import { UserContext } from "../../context/UserContext";
import {
  compareMLBLObject,
  compareMR2MRObject,
} from "../../services/mrService";
import MatchingDocumentTable from "./MatchingDocumentTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PropTypes from "prop-types";
import { getOrganisationFromUser } from "../../utils/organisationUtils";

const MatchPages = ({
  matchData,
  files1,
  files2,
  onMatchConfirmed,
  onMatchRemoved,
  comparedWithBl,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [inProgress, setInProgress] = useState(matchData);
  const [matched, setMatched] = useState([]);
  const [noMatch, setNoMatch] = useState([]);
  const { user } = useContext(UserContext);
  const theme = useTheme();

  const pages = Object.keys(matchData);
  const totalSteps = pages.length;
  const progress = ((matched.length + noMatch.length) / totalSteps) * 100;
  const [oddPages, setOddPages] = useState(
    pages.length !== matchData[pages[0]].length
  );

  const sortInProgress = (inProgress) => {
    const entries = Object.entries(inProgress);

    // Sort entries by the highest score in their arrays
    entries.sort(([, arrayA], [, arrayB]) => {
      // Get max score for each key's array
      const maxScoreA = Math.max(...arrayA.map((item) => item.score));
      const maxScoreB = Math.max(...arrayB.map((item) => item.score));
      return maxScoreB - maxScoreA;
    });

    return Object.fromEntries(entries);
  };

  useEffect(() => {
    // Sort matchData whenever it changes
    const sortedData = sortInProgress(matchData);
    setInProgress(sortedData);
  }, [matchData]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleNextPage = () => {
    setCurrentMatchIndex(0);
  };

  const handlePreviousMatch = () => {
    if (currentMatchIndex > 0) {
      setTimeout(() => {
        setCurrentMatchIndex(currentMatchIndex - 1);
      }, 300);
    }
  };

  const handleNextMatch = () => {
    setCurrentMatchIndex(currentMatchIndex + 1);
  };

  // Add to Matched List
  const handleConfirmMatch = async (doc1Id, doc2Id, score) => {
    const doc1 = findFileById(doc1Id, files1);
    const doc2 = findFileById(doc2Id, files2);
    setMatched((prev) => [...prev, { doc1, doc2, score }]);
    setInProgress((prev) => {
      const updated = Object.fromEntries(
        Object.entries(prev).filter(([key]) => key !== doc1Id)
      );
      return updated;
    });
    handleNextPage();
    if (comparedWithBl) {
      try {
        const response = await compareMLBLObject(
          doc1,
          doc2,
          getOrganisationFromUser(user),
          true
        );

        // Update compiled responses in the parent
        onMatchConfirmed(response);
      } catch (err) {
        console.log(err.message);
      }
    } else {
      try {
        const response = await compareMR2MRObject(
          doc1,
          doc2,
          getOrganisationFromUser(user),
          true
        );
        // Update compiled responses in the parent
        onMatchConfirmed(response);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  // Remove from Matched and Restore to In Progress
  const handleUnmatch = (doc1Id) => {
    setMatched((prev) => prev.filter((item) => item.doc1.doc_id !== doc1Id));
    const itemToReAdd = matchData[doc1Id];
    setInProgress((prev) => {
      return { ...prev, [doc1Id]: itemToReAdd };
    });
    // TODO: Might have to show an error here if it couldn't be unmatched?
    onMatchRemoved(doc1Id);
    // Reset the current match index to avoid errors
    setCurrentMatchIndex(0);
  };

  const handleRematch = (doc1Id) => {
    setNoMatch((prev) => prev.filter((item) => item.doc1.doc_id !== doc1Id));
    const itemToReAdd = matchData[doc1Id];
    setInProgress((prev) => {
      return { ...prev, [doc1Id]: itemToReAdd };
    });

    // Reset the current match index to avoid errors
    setCurrentMatchIndex(0);
  };

  const getPercentageStyle = (percentage) => {
    if (percentage > 0.75) {
      return { color: theme.palette.semantic.success, fontWeight: 600 };
    } else if (percentage > 0.5) {
      return { color: theme.palette.semantic.warning, fontWeight: 600 };
    } else {
      return { color: theme.palette.semantic.error, fontWeight: 600 };
    }
  };

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  // Add to No Match List
  const handleNoMatch = (doc1Id) => {
    const doc1 = findFileById(doc1Id, files1);
    setNoMatch((prev) => [...prev, { doc1, doc2: null }]);
    setInProgress((prev) => {
      const updated = Object.fromEntries(
        Object.entries(prev).filter(([key]) => key !== doc1Id)
      );
      return updated;
    });
    handleNextPage();
  };

  const findFileById = (id, files) => files.find((file) => file.doc_id === id);

  // TODO: Be consistent with the data structure either use array or object
  const getTabData = () => {
    if (activeTab === 0) return inProgress;
    if (activeTab === 1) return matched;
    if (activeTab === 2) return noMatch;
    return [];
  };

  const renderTable = () => {
    const currentData = getTabData();
    if (!currentData || Object.keys(currentData).length === 0) {
      return (
        <Typography align="center" sx={{ my: 4 }}>
          No documents available.
        </Typography>
      );
    }

    let doc1, doc2, currentItem, currentPageId;

    if (activeTab === 0) {
      currentPageId = Object.keys(inProgress)[0];
      const currentMatches = inProgress[currentPageId];
      currentItem = currentMatches[currentMatchIndex];

      doc1 = findFileById(currentPageId, files1);
      doc2 = findFileById(currentItem.doc_id, files2);
    } else if (activeTab === 1) {
      currentItem = matched[currentMatchIndex];
      doc1 = currentItem.doc1;
      doc2 = currentItem.doc2;
    } else if (activeTab === 2) {
      currentItem = noMatch[currentMatchIndex];
      doc1 = currentItem.doc1;
      doc2 = null;
      currentItem.score = 0;
    }

    return (
      <Box sx={{ p: 2 }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
            }}
          >
            {/* Left Section */}
            {renderMatchHeader(currentItem.score)}

            {/* Right Section */}
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button
                variant="outlined"
                onClick={handlePreviousMatch}
                disabled={currentMatchIndex === 0}
                sx={{
                  borderColor: "transparent",
                  color: theme.palette.text.primary,
                  "&:hover": {
                    borderColor: "transparent",
                  },
                  "&:disabled": {
                    borderColor: "transparent",
                  },
                }}
              >
                <ChevronLeftIcon />
                Previous
              </Button>
              {activeTab === 0 && (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => handleNoMatch(currentPageId)}
                    sx={{
                      borderColor: theme.palette.semantic.error,
                      color: theme.palette.semantic.error,
                      "&:hover": {
                        borderColor: theme.palette.semantic.error,
                      },
                      "&:focus": {
                        borderColor: theme.palette.semantic.error,
                      },
                    }}
                  >
                    No Match
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleConfirmMatch(
                        currentPageId,
                        doc2.doc_id,
                        currentItem.score
                      )
                    }
                  >
                    Confirm Match
                  </Button>
                </>
              )}
              {activeTab === 1 && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => handleUnmatch(doc1.doc_id)}
                >
                  Unmatch
                </Button>
              )}
              {activeTab === 2 && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => handleRematch(doc1.doc_id)}
                >
                  Re-match
                </Button>
              )}
              <Button
                variant="outlined"
                onClick={handleNextMatch}
                disabled={
                  Array.isArray(currentData)
                    ? currentMatchIndex === currentData.length - 1
                    : currentMatchIndex ===
                      currentData[Object.keys(currentData)[0]].length - 1
                }
                sx={{
                  borderColor: "transparent",
                  color: theme.palette.text.primary,
                  "&:hover": {
                    borderColor: "transparent",
                  },
                  "&:disabled": {
                    borderColor: "transparent",
                  },
                }}
              >
                Next
                <ChevronRightIcon />
              </Button>
            </Stack>
          </Box>
        </Box>
        <MatchingDocumentTable
          doc1={doc1}
          doc2={doc2}
          titles={comparedWithBl ? ["MR", "BL"] : ["MR", "MR"]}
          state={activeTab}
        />
      </Box>
    );
  };

  const renderMatchHeader = (score) => {
    const chipVariant =
      activeTab === 1 ? "success" : activeTab === 2 ? "error" : null;
    const chipLabel =
      activeTab === 1 ? "Matched" : activeTab === 2 ? "No Match" : null;

    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="h1" sx={getPercentageStyle(score)}>
          {(score * 100).toFixed(2)}%
        </Typography>
        <Stack direction="column" alignItems="center">
          <Typography variant="small">Match</Typography>
          <Typography variant="small">Score</Typography>
        </Stack>
        {chipVariant && <Chip variant={chipVariant} label={chipLabel} />}
      </Stack>
    );
  };

  return (
    <Card
      sx={{
        background: theme.palette.background.level1,
        my: 2,
        borderRadius: "20px",
        border: `2px solid ${theme.palette.primary.main}`,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="intro" sx={{ fontWeight: 600 }}>
            Match Pages
          </Typography>
          <Box sx={{ width: 200 }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ height: 8, borderRadius: 4 }}
            />
          </Box>
          <Typography variant="body1">
            Matched {`${matched.length} of ${pages.length}`}
          </Typography>
        </Box>

        <IconButton
          onClick={toggleExpand}
          sx={{ color: theme.palette.text.primary }}
        >
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={isExpanded}>
        <CardContent>
          {/* Tabs Section */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="primary"
          >
            <Tab label={`In Progress (${Object.keys(inProgress).length})`} />
            <Tab label={`Matched (${matched.length})`} />
            <Tab label={`No Match (${noMatch.length})`} />
          </Tabs>
          {oddPages && (
            <Alert
              sx={{ mt: 1 }}
              variant="outlined"
              severity="error"
              onClose={() => {
                setOddPages(false);
              }}
            >
              You have uploaded an odd number of documents for matching.
            </Alert>
          )}
          {renderTable()}
        </CardContent>
      </Collapse>
    </Card>
  );
};

MatchPages.propTypes = {
  matchData: PropTypes.object.isRequired,
  files1: PropTypes.array.isRequired,
  files2: PropTypes.array.isRequired,
  onMatchConfirmed: PropTypes.func.isRequired,
  onMatchRemoved: PropTypes.func.isRequired,
  comparedWithBl: PropTypes.bool,
};

export default MatchPages;
