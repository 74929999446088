// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./context/UserContext";
import { ThemeProviderComponent } from "./context/ThemeContext";
import { globalStyles } from "./styles/index";
import { applyGlobalStyles } from "./applyStyles";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <ThemeProviderComponent>
        <App />
      </ThemeProviderComponent>
    </UserProvider>
  </React.StrictMode>
);

reportWebVitals();

// Apply default styles
applyGlobalStyles(globalStyles);
