export const getOrganisationFromUser = (user) => {
  return [
    "admin.centurion@shipster.club",
    "admin.navigator@shipster.club",
    "admin.christiania@shipster.club",
    "admin.falcon@shipster.club",
  ].includes(user.username)
    ? "internal"
    : user.orgId;
};
