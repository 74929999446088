import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Collapse,
  IconButton,
  Divider,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const DiscrepanciesList = ({ discrepancies }) => {
  DiscrepanciesList.propTypes = {
    discrepancies: PropTypes.array.isRequired,
  };
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getSeverityColor = (severity) => {
    switch (severity) {
      case "HIGH":
        return theme.palette.semantic.error;
      case "MEDIUM":
        return theme.palette.semantic.warning;
      case "LOW":
        return theme.palette.semantic.success;
      default:
        return "inherit";
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.level1,
        color: theme.palette.text.primary,
        borderRadius: 3,
        border: "1px solid rgba(255, 255, 255, 0.2)",
        marginTop: 2,
        marginBottom: 4,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
        }}
      >
        <Typography variant="intro" sx={{ fontWeight: 600 }}>
          Discrepancies
        </Typography>
        <IconButton
          onClick={toggleExpand}
          sx={{ color: theme.palette.text.primary }}
        >
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      {/* Content */}
      <Collapse in={isExpanded}>
        <CardContent>
          {discrepancies
            .filter((row) => {
              // Check if any field in the row has content
              return (
                row.severity ||
                row.difference ||
                row.possibleCause ||
                row.suggestedAction
              );
            })
            .map((row, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                  paddingY: 2,
                  borderBottom:
                    index < discrepancies.length - 1
                      ? "1px solid rgba(255, 255, 255, 0.1)"
                      : "none",
                }}
              >
                {/* Vertical Divider as Colored Bar */}
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    width: 4,
                    backgroundColor: getSeverityColor(row.severity),
                    borderRadius: 1,
                  }}
                />

                {/* Text Content */}
                <Box>
                  {row.title && (
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                        marginBottom: 1,
                      }}
                    >
                      {row.title}
                    </Typography>
                  )}
                  {row.difference && row.discrepancy && (
                    <Typography
                      sx={{
                        marginBottom: 1,
                        color: theme.palette.text.secondary,
                        fontSize: "0.9rem",
                      }}
                    >
                      {row.difference} (Difference: {row.discrepancy})
                    </Typography>
                  )}
                  {row.possibleCause && (
                    <Typography
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "0.85rem",
                        marginBottom: 0.5,
                      }}
                    >
                      Possible Cause: {row.possibleCause}
                    </Typography>
                  )}
                  {row.suggestedAction && (
                    <Typography
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: "0.85rem",
                      }}
                    >
                      Suggested Action: {row.suggestedAction}
                    </Typography>
                  )}
                </Box>
              </Box>
            ))}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default DiscrepanciesList;
