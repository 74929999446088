import React, { useState, useContext } from "react";
import ThumbsUpIcon from "@mui/icons-material/ThumbUp";
import ThumbsDownIcon from "@mui/icons-material/ThumbDown";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Typography,
  TextField,
  Modal,
  IconButton,
  Grid2,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { sendUserFeedback } from "../../services/feedbackService";
import { UserContext } from "../../context/UserContext";
import PropTypes from "prop-types";
import { getOrganisationFromUser } from "../../utils/organisationUtils";

const Feedback = ({ requestId, type }) => {
  const [feedback, setFeedback] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const handleThumbsDownClick = () => {
    setModalOpen(true);
    setFeedback("thumbsDown");
  };

  const handleThumbsUpClick = () => {
    setFeedback("thumbsUp");
    setLoading(true);
    handleSendFeedback(true);
  };

  const resetFeedback = () => {
    setModalOpen(false);
    setFeedback(null);
    setAdditionalInfo("");
  };

  const handleSendFeedback = async (accepted) => {
    setLoading(true);
    try {
      await sendUserFeedback(
        getOrganisationFromUser(user),
        requestId,
        accepted,
        additionalInfo,
        type
      );
      setFeedbackSubmitted(true);
    } catch (error) {
      console.error("Failed to send feedback:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {/* Main Feedback Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" sx={{ mr: 2 }}>
            {feedbackSubmitted
              ? "Feedback sent. Thank you for your time!"
              : "Are you satisfied with the results?"}
          </Typography>

          {loading && feedback === "thumbsUp" && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </Box>

        {!feedback && (
          <>
            <Button
              onClick={handleThumbsUpClick}
              sx={{
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              <ThumbsUpIcon style={{ color: "grey" }} />
            </Button>
            <Button
              onClick={handleThumbsDownClick}
              sx={{
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              <ThumbsDownIcon style={{ color: "grey" }} />
            </Button>
          </>
        )}
      </Box>

      {/* Modal for Feedback Form */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: theme.palette.background.default,
            boxShadow: 24,
            borderRadius: "8px",
            p: 4,
            width: "400px",
            border: "1px solid #A2ACBB",
          }}
        >
          {feedbackSubmitted ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                  Feedback
                </Typography>
                <IconButton onClick={resetFeedback}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography varient="body1" sx={{ mb: 4 }}>
                Feedback sent. Thank you for your time!
              </Typography>
              <Button
                variant="outlined"
                onClick={() => setModalOpen(false)}
                sx={{ width: "100%" }}
              >
                Close
              </Button>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                  Feedback
                </Typography>
                <IconButton onClick={resetFeedback}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography variant="body1" sx={{ mb: 2 }}>
                We would love to hear your feedback on this app or its
                functionalities.
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Your Feedback"
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                multiline
                rows={5}
                fullWidth
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    background: theme.palette.background.level3,
                  },
                }}
                inputProps={{ maxLength: 3000 }}
              />
              <Typography
                sx={{ textAlign: "right", fontSize: "0.8rem", color: "#aaa" }}
              >
                {additionalInfo.length}/3000
              </Typography>
              <Grid2 container spacing={2}>
                <Grid2 size={3} key={1}>
                  <Button
                    variant="outlined"
                    onClick={resetFeedback}
                    sx={{ width: "100%" }}
                  >
                    Cancel
                  </Button>
                </Grid2>
                <Grid2 size={9} key={1}>
                  <Button
                    variant="contained"
                    onClick={() => handleSendFeedback(false)}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      width: "100%",
                    }}
                    disabled={loading}
                  >
                    Send
                  </Button>
                </Grid2>
              </Grid2>

              {loading && feedback === "thumbsDown" && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <CircularProgress />
                </Box>
              )}
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

Feedback.propTypes = {
  type: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
};

export default Feedback;
