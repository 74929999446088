import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { contentStyle } from "../styles/templateStyles";

export const getCenturionVITemplate = ({
  brokersEmail,
  cargo,
  cpDate,
  dischargeAgents,
  dischargeAgentEmail,
  dischargeRate,
  laycan,
  loadAgentEmail,
  loadingAgents,
  missingValue,
  portOfDischarge,
  portOfLoading,
  speed,
  vessel,
}) => {
  return {
    text1: `
            To Master M/V ${vessel} 

            Fm Centurion Bulk Pte Ltd 
 

            Dear Captain, 

            

            Good day, 

            
            1. We are hereby pleased to send you instructions for your next voyage employment as follows:
        
            - CP date: ${cpDate}

            - Laycan: ${laycan}

            - Cargo: ${cargo}

            - Loadport: ${portOfLoading}

            - Loadrate: ${missingValue}

            - Disch.port: ${portOfDischarge}

            - Disc.rate: ${dischargeRate}

            - Speed: ${speed}
            Please perform all sea voyages during our charter at vessels CP speed unless otherwise advised by WNI.
             

            Additional information: 

            - Cables, pipes etc to be protected so that is impossible for discharging equipment to contact them. 

            - Charterers undertake that loading of first layer of scrap not to be released until lowered as close as possible to tanktop and not to be dumped / dropped during loading. First layer of scrap to be loaded at an height and to be evenly stowed / trimmed to satisfaction of master before loading balance of cargo. In case of sufficient shredder loaded first in each hold this clause is not applicable method used for protecting tanktop to be as per customary load practice. 

            - Vessel to provide free charge of electricity of 440 V - 60 A, - min 45 KW per grabs/crane to operate receivers grabs simultanously. vessel to supply 440 v 3 phases 60 cycles and 45kw/60a per cranes from the power supply from cranes or engine room for discharging. 

            - Master to sign 'clean on board bs/l' and have the right and obligation to reject unclean / unfit cargo 

            - We have arranged for following company to perform a condition survey in (${portOfLoading} less berthing terms)) in order to detect all detect all damages caused by stevedores in load and discharge port in case any and also go onboard and check vessels power output for the grabs for discharging as per agreement with Owners: 
        
            ${missingValue}

            2. Agents details as follows 

             

            Load port agents: ${loadingAgents}

             
            Discharge port agents: ${dischargeAgents}

            
            3. Stowage plan 

            Please provide us with vessels preliminary stowage plan basis information provided above and vessel max possible cargo intake. Kindly provide us with vessels DWT calculations (with all weights) including details of vessels BROB on departure load port together with departure drafts and FW/Constants ROB. Please also indicate BROB arrival discharge port, arrival draft Discharge port and FW/Constants ROB. Please send loadicator printout. 

         

            In our experience dead ballast can be reduced significantly if you adjust the loading sequence so that vessel is trimming aft 2,5-3 meters in the fist stages of the loading and de-ballasting period. This way you would be able to reduce dead ballast by stripping ballast tanks with a large trim to next to nothing - in this case less that a few tons in each tank. Of course this requires planning and attention to the matter, but we are sure you can undertake this task in a deeply professionally manner. 

            

            4. Notices at loadport and discharge port: 

            
            Load: Please provide a daily notices to following addresses for load port: 

            operations@centurionbulk.com 

            ${brokersEmail}
            ${loadAgentEmail}

            ${dischargeAgentEmail}

            
            

            Discharge: Please provide a daily notices to following addresses for discharge port: 

            operations@centurionbulk.com 

            ${brokersEmail}
            ${dischargeAgentEmail}

            
            5. Bunker planning: 

            ${missingValue}

            

            6. NOR: 

            I. Vessel to tender NOR immediately upon arrival at the port area: commercial, fiscal and administrative area of the port. This can be well before the actual anchorage or pilot station - please check with agents in advance. 

            II. Retender NOR at the berth 

            III. If vessel is not to berth immediately upon arrival, please re-tender NOR at upon arrival vessels customary waiting/anchorage area. NOR may be tendered atdn shinc. Please ensure that you have applied for free pratique well in advance of arrival port, and that NOR is tendered WIBON/WIFPON. 

            Notice of readiness to be tendered as soon as vessel has arrived and is ready to load or discharge any time day/night Sundays and holidays included, whether in port or not, whether in free pratique or not, whether customs cleared or not. This NOR must be tendered “Without prejudice to original (previous) notice of readiness dated….. ” 

            IV. To avoid that charterers dispute the validity of the notice for any reason, you should then give second, third and fourth notice of readiness, when vessel berths and again with the subsequent notices “Without prejudice to original (previous) notice of readiness dated….. ”

            
            Please also include total cargo quantity to be loaded/discharged in the notice of readiness after consulting with us for maximum cargo intake. 

            
            At first opportunity, Please hand over to agents your “confirmation of notice of readiness” in writing and showing the same date and time as the cable you have dispatched. Regarding date / time of acceptance, we would like to ask you for the following remark always to be inserted be agents / shippers / receivers “To be accepted as per the provisions of the charter party”


            

            7. Bills of Lading & SOF 


            Please present the proforma Bill of Lading to us. 

            Please authorise agents to sign Bills of Lading on behalf of vessel always provided in strict conformity with Mate's Receipt. 

            Please do not sign or authorize signature for any 'freight prepaid' Bills of Lading unless otherwise instructed by us. 

            
            When you will sign the original SOF please be careful, take time to read it slowly and check all remarks. 

            If you don't agree with some remarks please strikeout the line and put the correct comments. 

            

            Also please sign each page of SOF to be sure agent have presented you all pages and not only the first and last. 

            

            8. Special attention 

            It is imperative that all stevedore damages are photographed and reported immediately upon detection of damages. 

            
            Therefore kindly confirm safe receipt of these instructions together with: 


            a) Daily ETA Notices to Centurion Bulk and agents starting right now. 

            b) Pre-stowplan bss above details at the earliest 

            c) Bunker requirement for the voyage bss full speed and ECO speed 


            Thank you

 
            `,
  };
};

export const getFilledCenturionVITemplate = ({
  vessel,
  cpDate,
  laycan,
  speed,
  cargo,
  loadingAgents,
  loadAgentEmail,
  loadRate,
  dischargeAgents,
  dischargeAgentEmail,
  dischargeRate,
  portOfLoading,
  portOfDischarge,
  brokersEmail,
  missingValue,
}) => {
  const additionalInformationList = [
    "- Cables, pipes, etc., to be protected so that it is impossible for discharging equipment to contact them.",
    "- Charterers undertake that loading of the first layer of scrap not to be released until lowered as close as possible to tanktop and not to be dumped/dropped during loading. The first layer of scrap to be loaded at a height and to be evenly stowed/trimmed to the satisfaction of the master before loading the balance of the cargo. In case sufficient shredder is loaded first in each hold, this clause is not applicable. The method used for protecting tanktop to be as per customary load practice.",
    "- Vessel to provide free charge of electricity of 440 V - 60 A, min 45 KW per grab/crane to operate receivers’ grabs simultaneously. Vessel to supply 440 V, 3 phases, 60 cycles, and 45 KW/60 A per crane from the power supply from cranes or engine room for discharging.",
    "- Master to sign 'clean on board BS/L' and have the right and obligation to reject unclean/unfit cargo.",
  ];

  const norList = [
    "I. Vessel to tender NOR immediately upon arrival at the port area: commercial, fiscal and administrative area of the port. This can be well before the actual anchorage or pilot station - please check with agents in advance.",
    "II. Retender NOR at the berth.",
    "III. If vessel is not to berth immediately upon arrival, please re-tender NOR upon arrival at the vessel's customary waiting/anchorage area. NOR may be tendered atdn shinc. Please ensure that you have applied for free pratique well in advance of arrival port, and that NOR is tendered WIBON/WIFPON. Notice of readiness to be tendered as soon as vessel has arrived and is ready to load or discharge any time day/night Sundays and holidays included, whether in port or not, whether in free pratique or not, whether customs cleared or not. This NOR must be tendered “Without prejudice to original (previous) notice of readiness dated…..”",
    "IV.To avoid that charterers dispute the validity of the notice for any reason, you should then give second, third and fourth notice of readiness, when vessel berths and again with the subsequent notices “Without prejudice to original (previous) notice of readiness dated…..”",
  ];

  const specialAttentionList = [
    "a) Daily ETA Notices to Centurion Bulk and agents starting right now. ",
    "b) Pre-stowplan bss above details at the earliest ",
    "c) Bunker requirement for the voyage bss full speed and ECO speed ",
  ];

  return (
    <Box sx={contentStyle} id="text-content">
      <Typography variant="body1">To Master {vessel}</Typography>
      <Typography variant="body1">Fm Centurion Bulk Pte Ltd</Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Dear Captain,
      </Typography>
      <Typography sx={{ mt: 4 }} variant="body1">
        Good day,
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        1. We are hereby pleased to send you instructions for your next voyage
        employment as follows:
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        - CP date : &nbsp;
        {cpDate}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        - Laycan : &nbsp;
        {laycan}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        - Cargo : &nbsp;
        {cargo}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        - Loadport : &nbsp;
        {portOfLoading}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        - Loadrate : &nbsp;
        {loadRate}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        - Disch.port : &nbsp;
        {portOfDischarge}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        - Disc.rate : &nbsp;
        {dischargeRate}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        - Speed : &nbsp;
        {speed}
      </Typography>
      <Typography variant="body1">
        Please perform all sea voyages during our charter at vessels CP speed
        unless otherwise advised by WNI.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Additional information:
      </Typography>
      <List
        sx={{
          listStyleType: "none",
          pl: 0,
        }}
      >
        {additionalInformationList.map((item, index) => (
          <ListItem
            key={`cleaning-${index}`}
            sx={{ display: "list-item", py: 0.0 }}
          >
            <ListItemText primary={item} />
          </ListItem>
        ))}
        <ListItem sx={{ display: "list-item", py: 0.0 }}>
          - We have arranged for the following company to perform a condition
          survey in &nbsp;
          {portOfLoading}
          &nbsp; less berthing terms to detect all damages caused by stevedores
          in load and discharge ports, if any, and also go onboard to check
          vessel&apos;s power output for the grabs for discharging as per
          agreement with Owners.
        </ListItem>
      </List>
      <Typography sx={{ mt: 2 }} variant="body1">
        {missingValue}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        2. Agents details as follows
      </Typography>
      <Typography sx={{ mt: 4 }} variant="body1">
        Load port agents: &nbsp;
        {loadingAgents}
      </Typography>
      <Typography sx={{ mt: 4 }} variant="body1">
        Discharge port agents: &nbsp;
        {dischargeAgents}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        3. Stowage plan
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Please provide us with vessels preliminary stowage plan basis
        information provided above and vessel max possible cargo intake. Kindly
        provide us with vessels DWT calculations (with all weights) including
        details of vessels BROB on departure load port together with departure
        drafts and FW/Constants ROB. Please also indicate BROB arrival discharge
        port, arrival draft Discharge port and FW/Constants ROB. Please send
        loadicator printout.
      </Typography>
      <Typography sx={{ mt: 6 }} variant="body1">
        In our experience dead ballast can be reduced significantly if you
        adjust the loading sequence so that vessel is trimming aft 2,5-3 meters
        in the fist stages of the loading and de-ballasting period. This way you
        would be able to reduce dead ballast by stripping ballast tanks with a
        large trim to next to nothing - in this case less that a few tons in
        each tank. Of course this requires planning and attention to the matter,
        but we are sure you can undertake this task in a deeply professionally
        manner.
      </Typography>
      <Typography sx={{ mt: 8 }} variant="body1">
        4. Notices at loadport and discharge port:
      </Typography>
      <Typography sx={{ mt: 4 }} variant="body1">
        Load: Please provide a daily notices to following addresses for load
        port:
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        operations@centurionbulk.com
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        {brokersEmail}
      </Typography>
      <Typography variant="body1">{loadAgentEmail}</Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        {dischargeAgentEmail}
      </Typography>
      <Typography sx={{ mt: 10 }} variant="body1">
        Discharge: Please provide a daily notices to following addresses for
        discharge port:
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        operations@centurionbulk.com
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        {brokersEmail}
      </Typography>
      <Typography variant="body1">{dischargeAgentEmail}</Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        5. Bunker planning:
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        {missingValue}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        6. NOR:
      </Typography>
      <List
        sx={{
          listStyleType: "upper-roman",
          pl: 4,
        }}
      >
        {norList.map((item, index) => (
          <ListItem
            key={`cleaning-${index}`}
            sx={{ display: "list-item", py: 0.2 }}
          >
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
      <Typography sx={{ mt: 4 }} variant="body1">
        Please also include total cargo quantity to be loaded/discharged in the
        notice of readiness after consulting with us for maximum cargo intake.{" "}
      </Typography>
      <Typography sx={{ mt: 4 }} variant="body1">
        At first opportunity, Please hand over to agents your “confirmation of
        notice of readiness” in writing and showing the same date and time as
        the cable you have dispatched. Regarding date / time of acceptance, we
        would like to ask you for the following remark always to be inserted be
        agents / shippers / receivers “To be accepted as per the provisions of
        the charter party”{" "}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        7. Bills of Lading & SOF
      </Typography>
      <Typography sx={{ mt: 4 }} variant="body1">
        Please present the proforma Bill of Lading to us.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Please authorise agents to sign Bills of Lading on behalf of vessel
        always provided in strict conformity with Mate&apos;s Receipt.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Please do not sign or authorize signature for any &apos;freight
        prepaid&apos; Bills of Lading unless otherwise instructed by us.
      </Typography>
      <Typography sx={{ mt: 4 }} variant="body1">
        When you will sign the original SOF please be careful, take time to read
        it slowly and check all remarks.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        If you don&apos;t agree with some remarks please strikeout the line and
        put the correct comments.
      </Typography>
      <Typography sx={{ mt: 4 }} variant="body1">
        Also please sign each page of SOF to be sure agent have presented you
        all pages and not only the first and last.
      </Typography>
      <Typography sx={{ mt: 4 }} variant="body1">
        8. Special attention
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        It is imperative that all stevedore damages are photographed and
        reported immediately upon detection of damages.
      </Typography>
      <Typography sx={{ mt: 4 }} variant="body1">
        Therefore kindly confirm safe receipt of these instructions together
        with:
      </Typography>
      <List
        sx={{
          listStyleType: "none",
          pl: 0,
        }}
      >
        {specialAttentionList.map((item, index) => (
          <ListItem
            key={`cleaning-${index}`}
            sx={{ display: "list-item", py: 0.0 }}
          >
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
      <Typography variant="body1" sx={{ mt: 2 }}>
        Thank you
      </Typography>
    </Box>
  );
};
