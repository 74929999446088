import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import EastIcon from "@mui/icons-material/East";
import PropTypes from "prop-types";

const ToolHeader = ({ title, subtext }) => {
  ToolHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtext: PropTypes.string.isRequired,
  };
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ mb: 3 }}>
      <Box
        onClick={() => {
          navigate("/dashboard");
        }}
        sx={{
          display: "inline-flex",
          alignItems: "center",
          cursor: "pointer",
          color: theme.palette.primary.main,
          mb: 2,
        }}
      >
        <ChevronLeftIcon
          sx={{ color: "inherit", marginRight: "4px", verticalAlign: "middle" }}
        />
        <Typography variant="body1" sx={{ color: "inherit" }}>
          Back to AI Tools
        </Typography>
      </Box>

      <Typography
        variant="h1"
        sx={{ fontWeight: 600, color: theme.palette.text.primary }}
      >
        {title.split(/(<->|->)/).map((part, index) => {
          if (part === "->") {
            return (
              <EastIcon
                key={index}
                sx={{
                  color: theme.palette.text.primary,
                  mx: 1,
                  verticalAlign: "middle",
                }}
              />
            );
          } else if (part === "<->") {
            return (
              <CompareArrowsIcon
                key={index}
                sx={{
                  color: theme.palette.text.primary,
                  mx: 1,
                  verticalAlign: "middle",
                }}
              />
            );
          }
          return <React.Fragment key={index}>{part.trim()}</React.Fragment>;
        })}
      </Typography>
      {subtext && (
        <Typography
          variant="h4"
          sx={{
            fontWeight: 400,
            color: theme.palette.text.secondary,
            marginTop: theme.spacing(0.5),
          }}
        >
          {subtext}
        </Typography>
      )}
    </Box>
  );
};

export default ToolHeader;
