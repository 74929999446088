import React from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { contentStyle } from "../styles/templateStyles";
import {
  Paragraph as DocxParagraph,
  Table as DocxTable,
  TableRow as DocxTableRow,
  TableCell as DocxTableCell,
  WidthType,
} from "docx";

export const getChristianiaVITemplate = ({
  brokers,
  brokersEmail,
  charterer,
  cleaning,
  cpDate,
  dischargeAgents,
  dischargeAgentEmail,
  heating,
  laycan,
  loadAgentEmail,
  loadingAgents,
  missingValue,
  portOfDischarge,
  portOfLoading,
  previousPort,
  speed,
}) => {
  return {
    text1: `
            Good day Captain ${missingValue},
            
            Upon completion discharging at ${previousPort}, please proceed to ${portOfLoading} for loading as follows:
            
            **<b>Kindly keep the broker : </b>${brokers} - email: ${brokersEmail} daily updated of eta/port times pertaining to this voyage - any other information/incidents etc. please contact your operator for instructions**
            
            <b>Voyage number : </b>${missingValue}
            
            <b>Charterers : </b>${charterer}
            
            <b>Laycan : </b>${laycan}
            Pls do not give eta 1st load port after laycan without instructions from your operator.
            
            <b>CP date : </b>${cpDate}
            
            <b>Rotation : </b>
            1st load port One safe berth/port ${portOfLoading}
            1st discharge port One safe berth/port ${portOfDischarge}
            
            <b>Consumption / Speed : </b>
            ${speed},
            As per default instructions, constant consumption/power.
        `,
    text2: `
            <b>NOR : </b>Tender NOR for ${missingValue} mts,
            <i>NOR not to be tendered prior laydays unless otherwise instructed by this office </i><c>(to be amended/deleted pending cp/instructions)</c>,

            When proceeding directly to berth, NOR to be tendered at EOSP. When not proceeding to berth on arrival, NOR to be tendered at anchor down OR commence drifting. 
            NOR to be always re-tendered at all fast without prejudice to any NOR previously tendered. Any change of vessel/’s state in between i.e., re-anchoring, drifting etc.… please retender NOR without prejudice to previous NOR tendered.

            <b>Cleaning : </b>${cleaning}
        `,
    cleaningList: `
            Send copy cleaning plan to Frede Hansen (kfh@christianiashipping.com) with CC to your operator - spectrometer to be used to monitor washing water if you have spectrometer onboard 
            If voyage fixed with WWT (wall wash test) prior loading, ALWAYS have Frede Hansen to approve your cleaning plan prior loading 
            For normal cleaning advice please contact Frede Hansen
        `,
    text3: `
            <b>Spectrometer : </b>Send spectrometer graphs / spectrometer discussions to L&I Maritime (operations@limaritime.com) with CC to your operator if you have spectrometer onboard,

            <b>Stowage : </b>copied from fixslip / last cargo requirement etc.

            <b>Heating : </b>${heating}

            <b>Inerting/padding : </b>(IGS or N2)

            <b>Prewashing : </b>

            <b>Pumping : </b>Pump rate ${missingValue} mt/hr or 100 psi (7 bars)

            <b>Emission report : </b>

            <b>Agents load : </b>${loadingAgents} - ${loadAgentEmail}

            <b>Agents disch : </b>${dischargeAgents} - ${dischargeAgentEmail}

            <b>Kindly revert regarding : </b>
        `,
    regardingList: `
            Stowage plan always consistent with safe arrival/departure all ports.
            Tank cleaning plan(s) including total time required (cleaning plan from previous voyage to this cargo and a cleaning plan for this cargo to water white standard/next cargo),
            Maximum cargo intake within cargo option (per grade). 
            Bunkers ROB and max intake at ${portOfLoading} 
            FW, sludge (pls clarify if tank cleaning or domestic), bilge, slops etc. - requirements, quantity and place of request. 
            Prewashing - if any - quantity expected to land to shore. 
            Inerting - if any - expected time/consumption of bunkers. 
            Slops generated after Annex 1 product and intended stowage of slops 
            Slops ROB first load port - Slops An I and An II to be disposed/decanted enroute in accordance with Marpol.
        `,
    noteText: `
            ** <b>NOTE </b> ** 
            <b>We refer to the CS Voyage manual and the BL requirements CS 2023 Edition sent to all vessels 16th May 2023, which will form part of these voyage orders. </b>
            
            Kindly forward any questions etc. you may have to the above.
        `,
  };
};

export const getChristianiaCargoTable = (cargo, missingValue) => {
  const width = { size: 3000, type: WidthType.DXA }; // Width in twips (3000 = 2.08 inches)
  return new DocxTable({
    rows: [
      // Table header row
      new DocxTableRow({
        height: {
          value: 1000,
          rule: "atleast",
        },
        children: [
          new DocxTableCell({ children: [new DocxParagraph("Cargo")], width }),
          new DocxTableCell({
            children: [new DocxParagraph("Cargo quantity & option")],
            width,
          }),
          new DocxTableCell({
            children: [new DocxParagraph("IBC Cargo name / Trade name")],
            width,
          }),
          new DocxTableCell({ children: [new DocxParagraph("S.G.")], width }),
          new DocxTableCell({
            children: [new DocxParagraph("Marpol category")],
            width,
          }),
          new DocxTableCell({
            children: [new DocxParagraph("IBC 16.2.6 (Y/N)")],
            width,
          }),
          new DocxTableCell({
            children: [new DocxParagraph("IBC 16.2.7 (Y/N)")],
            width,
          }),
          new DocxTableCell({
            children: [new DocxParagraph("IBC 16.2.9 (Y/N)")],
            width,
          }),
        ],
      }),
      // Table body row
      new DocxTableRow({
        height: {
          value: 1000,
          rule: "atleast",
        },
        children: [
          new DocxTableCell({ children: [new DocxParagraph("1")], width }),
          new DocxTableCell({
            children: [new DocxParagraph(cargo || "N/A")],
            width,
          }),
          new DocxTableCell({
            children: [new DocxParagraph(missingValue || "N/A")],
            width,
          }),
          new DocxTableCell({
            children: [new DocxParagraph(missingValue || "N/A")],
            width,
          }),
          new DocxTableCell({
            children: [new DocxParagraph(missingValue || "N/A")],
            width,
          }),
          new DocxTableCell({
            children: [new DocxParagraph(missingValue || "N/A")],
            width,
          }),
          new DocxTableCell({
            children: [new DocxParagraph(missingValue || "N/A")],
            width,
          }),
          new DocxTableCell({
            children: [new DocxParagraph(missingValue || "N/A")],
            width,
          }),
        ],
      }),
    ],
    width: {
      size: 10000,
      type: WidthType.DXA,
    },
  });
};

export const getFilledChristianiaVITemplate = ({
  formData,
  cpDate,
  charterer,
  laycan,
  speed,
  cleaning,
  cargo,
  heating,
  loadingAgents,
  loadAgentEmail,
  dischargeAgents,
  dischargeAgentEmail,
  portOfLoading,
  portOfDischarge,
  previousPort,
  broker,
  brokersEmail,
  missingValue,
}) => {
  const cleaningList = [
    "Send copy cleaning plan to Frede Hansen (kfh@christianiashipping.com) with CC to your operator - spectrometer to be used to monitor washing water if you have spectrometer onboard ",
    "If voyage fixed with WWT (wall wash test) prior loading, ALWAYS have Frede Hansen to approve your cleaning plan prior loading ",
    "For normal cleaning advice please contact Frede Hansen",
  ];

  const regardingList = [
    "Stowage plan always consistent with safe arrival/departure all ports.",
    "Maximum cargo intake within cargo option (per grade). ",
    `Bunkers ROB and max intake at ${formData.portOfLoading} `,
    "FW, sludge (pls clarify if tank cleaning or domestic), bilge, slops etc. - requirements, quantity and place of request. ",
    "Prewashing - if any - quantity expected to land to shore. ",
    "Inerting - if any - expected time/consumption of bunkers. ",
    "Slops generated after Annex 1 product and intended stowage of slops ",
    "Slops ROB first load port - Slops An I and An II to be disposed/decanted enroute in accordance with Marpol.",
  ];

  return (
    <Box sx={contentStyle} id="text-content">
      <Typography variant="body1">Good day Captain</Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Upon completion discharging at &nbsp;
        {previousPort}
        &nbsp;, please proceed to &nbsp;
        {portOfLoading}
        &nbsp;. for loading as follows :&nbsp;
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        **Kindly keep the broker &nbsp;
        {broker}
        &nbsp;- email: &nbsp;
        {brokersEmail}
        &nbsp;daily updated of eta/port times pertaining to this voyage - any
        other information/incidents etc. please contact your operator for
        instructions **&nbsp;
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Voyage number : &nbsp;
        {missingValue}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Charterers : &nbsp;
        {charterer}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Laycan : &nbsp;
        {laycan}
      </Typography>
      <Typography variant="body1">
        Pls do not give eta 1st load port after laycan without instructions from
        your operator.{" "}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        CP date : &nbsp;
        {cpDate}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Rotation :{" "}
      </Typography>
      <Typography variant="body1">
        1st load port One safe berth/port &nbsp;
        {portOfLoading}
      </Typography>
      <Typography variant="body1">
        1st discharge port One safe berth/port &nbsp;
        {portOfDischarge}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Consumption / Speed : &nbsp;
        {speed}
      </Typography>
      <Typography variant="body1">
        As per default instructions, constant consumption/power.
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2, mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cargo</TableCell>
              <TableCell>Cargo quantity & option</TableCell>
              <TableCell>IBC Cargo name / Trade name</TableCell>
              <TableCell>S.G.</TableCell>
              <TableCell>Marpol category</TableCell>
              <TableCell>IBC 16.2.6 (Y/N)</TableCell>
              <TableCell>IBC 16.2.7 (Y/N)</TableCell>
              <TableCell>IBC 16.2.9 (Y/N)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>{cargo}</TableCell>
              <TableCell>{missingValue}</TableCell>
              <TableCell>{missingValue}</TableCell>
              <TableCell>{missingValue}</TableCell>
              <TableCell>{missingValue}</TableCell>
              <TableCell>{missingValue}</TableCell>
              <TableCell>{missingValue}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="body1">
        NOR : Tender NOR for &nbsp;
        {missingValue}
        &nbsp; mts
      </Typography>
      <Typography sx={{ fontStyle: "italic" }} variant="body1">
        NOR not to be tendered prior laydays unless otherwise instructed by this
        office (to be amended/deleted pending cp/instructions)
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        When proceeding directly to berth, NOR to be tendered at EOSP. When not
        proceeding to berth on arrival, NOR to be tendered at anchor down OR
        commence drifting.
      </Typography>
      <Typography variant="body1">
        NOR to be always re-tendered at all fast without prejudice to any NOR
        previously tendered. Any change of vessel/’s state in between i.e.,
        re-anchoring, drifting etc.… please retender NOR without prejudice to
        previous NOR tendered.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Cleaning : &nbsp;
        {cleaning}
      </Typography>
      <List
        sx={{
          listStyleType: "disc",
          pl: 4,
        }}
      >
        {cleaningList.map((item, index) => (
          <ListItem
            key={`cleaning-${index}`}
            sx={{ display: "list-item", py: 0.0 }}
          >
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
      <Typography sx={{ mt: 2 }} variant="body1">
        Spectrometer : Send spectrometer graphs / spectrometer discussions to
        L&I Maritime (operations@limaritime.com) with CC to your operator if you
        have spectrometer onboard
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Stowage : copied from fixslip / last cargo requirement etc.{" "}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Heating : &nbsp;
        {heating}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Inerting/padding : (IGS or N2)
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Prewashing :{" "}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Pumping : Pump rate &nbsp;
        {missingValue}
        &nbsp; mt/hr or 100 psi (7 bars)
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Emission report :{" "}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Agents load :&nbsp;
        {loadingAgents}
        &nbsp;- &nbsp;
        {loadAgentEmail}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Agents disch :&nbsp;
        {dischargeAgents}
        &nbsp;- &nbsp;
        {dischargeAgentEmail}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body1">
        Kindly revert regarding :
      </Typography>
      <List
        sx={{
          listStyleType: "disc",
          pl: 4,
        }}
      >
        {regardingList.map((item, index) => (
          <ListItem
            key={`cleaning-${index}`}
            sx={{ display: "list-item", py: 0.0 }}
          >
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
      <Typography variant="body2" sx={{ mt: 2 }}>
        ** NOTE **
      </Typography>
      <Typography variant="body2">
        We refer to the CS Voyage manual and the BL requirements CS 2023 Edition
        sent to all vessels 16th May 2023, which will form part of these voyage
        orders.
      </Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        Kindly forward any questions etc. you may have to the above.
      </Typography>
    </Box>
  );
};
