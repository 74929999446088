import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Collapse,
  Alert,
  Typography,
  Grid2,
  CircularProgress,
} from "@mui/material";
import { compareHireStatements } from "../../services/hireService";
import HireStatementResult from "./HireStatementResult";
import Feedback from "../../components/common/Feedback";
import TwoDocumentUpload from "../../components/common/TwoDocumentUpload";
import DocumentUploader from "../../components/common/DocumentUploader";
import ToolHeader from "../../components/common/ToolHeader";
import ToolStepProgressBar from "../../components/common/ToolStepProgressBar";
import Step1Buttons from "../../components/common/Step1Buttons";
import { UserContext } from "../../context/UserContext";
import CloseFooter from "../../components/common/CloseFooter";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@emotion/react";
import { getOrganisationFromUser } from "../../utils/organisationUtils";

const HireStatement = () => {
  const theme = useTheme();

  const [filesSection1, setFilesSection1] = useState([]);
  const [filesSection2, setFilesSection2] = useState([]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [useOCR, setUseOCR] = useState(true);
  const [useVision, setUseVision] = useState(true);
  const [useContentCache, setUseContentCache] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const { user } = useContext(UserContext);
  const steps = ["Upload & Compare", "Results"];
  const [currentStep, setCurrentStep] = useState(0);

  const handleStepChange = (step) => {
    if (step <= currentStep) setCurrentStep(step);
  };

  const handleAdvancedOptionsClick = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const handleCompare = async () => {
    if (filesSection1.length === 0 || filesSection2.length === 0) {
      alert("Please upload a file in both sections before comparing.");
      return;
    }

    setLoading(true);
    setError(null); // Clear any previous errors
    setResult(null); // Clear previous result

    try {
      const comparisonResult = await compareHireStatements(
        filesSection1,
        filesSection2,
        useOCR,
        useVision,
        useContentCache,
        getOrganisationFromUser(user)
      );
      setResult(comparisonResult);
      setCurrentStep(1);
      console.log(comparisonResult);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={3}>
      <ToolHeader
        title={"Hire Statement"}
        subtext={"Compare the totals from two Hire Statements"}
      />
      <ToolStepProgressBar
        steps={steps}
        currentStep={currentStep}
        onStepChange={handleStepChange}
      />

      <Alert
        icon={
          <InfoIcon
            fontSize="inherit"
            sx={{ color: theme.palette.semantic.info }}
          />
        }
        sx={{
          border: `1px solid ${theme.palette.semantic.info}`,
          borderLeft: `8px solid ${theme.palette.semantic.info}`,
          borderRadius: "4px",
        }}
      >
        <Typography variant="body1" sx={{ color: "white" }}>
          The Hire Statements Comparison Tool is being updated to improve
          accuracy. Limited functionality may occur during this time. Thank you
          for your patience!
        </Typography>
      </Alert>

      {(!result || currentStep === 0) && (
        <>
          {error && (
            <Alert severity="error" sx={{ my: 2 }}>
              {error}
            </Alert>
          )}

          <TwoDocumentUpload>
            <Grid2 size={6}>
              <DocumentUploader
                files={filesSection1}
                setFiles={setFilesSection1}
                title="Upload first hire statement PDF"
                setResult={setResult}
                allowedFileTypes={["pdf", "docx"]}
                singleFile={true}
              />
            </Grid2>

            <Grid2 size={6}>
              <DocumentUploader
                files={filesSection2}
                setFiles={setFilesSection2}
                title="Upload second hire statement PDF"
                setResult={setResult}
                allowedFileTypes={["pdf", "docx"]}
                singleFile={true}
              />
            </Grid2>
          </TwoDocumentUpload>

          {user.isAdmin && (
            <>
              {/* Advanced Options Toggle */}
              <Button
                variant="outlined"
                onClick={handleAdvancedOptionsClick}
                sx={{ mt: 3 }}
              >
                {showAdvancedOptions
                  ? "Hide Advanced Options"
                  : "Show Advanced Options"}
              </Button>

              {/* Advanced Options Section */}
              <Collapse in={showAdvancedOptions}>
                <Box sx={{ border: "1px solid #ccc", padding: "10px", mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useOCR}
                        onChange={(e) => setUseOCR(e.target.checked)}
                      />
                    }
                    label="Use OCR for Hire"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useVision}
                        onChange={(e) => setUseVision(e.target.checked)}
                      />
                    }
                    label="Use Vision for Hire"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useContentCache}
                        onChange={(e) => setUseContentCache(e.target.checked)}
                      />
                    }
                    label="Use Cache for Hire"
                  />
                </Box>
              </Collapse>
            </>
          )}

          {/* Compare Button */}
          <Step1Buttons
            title={"Compare Hire Statements"}
            validation={
              filesSection1?.length === 0 ||
              filesSection2?.length === 0 ||
              loading
            }
            handleMoveNextStep={handleCompare}
          ></Step1Buttons>

          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </>
      )}

      {result && currentStep === 1 && (
        <>
          <HireStatementResult comparisonResult={result}></HireStatementResult>
          <Feedback requestId={result.request_id} type={"Hire"}></Feedback>
          <CloseFooter />
        </>
      )}
    </Box>
  );
};

export default HireStatement;
