import axios from "axios";
import { API_URL } from "./config";

export const loi2loi = async (
  files,
  options = {
    useOCR: true,
    useVision: true,
    useContentCache: true,
    useLOICache: true,
    oneEntityPerPage: [],
    pageRanges: null,
    organisationId: "",
  }
) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("original_lois", file);
  });
  const oneEntityPerPage = options.oneEntityPerPage.length
    ? options.oneEntityPerPage
    : Array(files.length).fill(false);

  formData.append("one_entity_per_page", JSON.stringify(oneEntityPerPage));
  formData.append("ocr", options.useOCR);
  formData.append("vision", options.useVision);
  formData.append("use_cache", options.useContentCache);
  if (options.pageRanges) {
    formData.append("page_ranges", JSON.stringify(options.pageRanges));
  }
  formData.append("organisation_id", options.organisationId);

  try {
    const response = await axios.post(
      `${API_URL}/loi2loi/multiple_loi2loi`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error extracting LOI:", error);
    throw error;
  }
};
