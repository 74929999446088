import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  CardContent,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Button,
  Collapse,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableChartIcon from "@mui/icons-material/TableChart";

const ComparisonTable = ({
  children,
  onCopy,
  onSavePdf,
  onSaveExcel,
  filterOptions,
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(true);
  const [filter, setFilter] = useState("all");

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // Normalize children into an array
  const childrenArray = React.Children.toArray(children);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.level1,
        color: theme.palette.text.primary,
        borderRadius: 3,
        border: "1px solid rgba(255, 255, 255, 0.2)",
        marginTop: 2,
        marginBottom: 4,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography variant="intro" sx={{ fontWeight: 600 }}>
          Comparison Results
        </Typography>
        <IconButton
          onClick={toggleExpand}
          sx={{ color: theme.palette.text.primary }}
        >
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>

      {/* Content */}
      <Collapse in={isExpanded}>
        <CardContent>
          {/* Details section */}
          {childrenArray[0] && (
            <Box>{React.cloneElement(childrenArray[0])}</Box>
          )}
          {/* Filter and Action Buttons Row */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 2,
              marginTop: 4,
            }}
          >
            {/* Filter Options */}
            <RadioGroup
              row
              value={filter}
              onChange={handleFilterChange}
              sx={{ gap: 2 }}
            >
              {filterOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio sx={{ color: theme.palette.primary.main }} />}
                  label={
                    <Typography sx={{ color: theme.palette.text.primary }}>
                      {option.label}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>

            {/* Action Buttons */}
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="outlined"
                startIcon={<ContentCopyIcon />}
                onClick={onCopy(filter)}
                sx={{ textTransform: "none" }}
              >
                Copy to Clipboard
              </Button>
              <Button
                variant="contained"
                startIcon={<PictureAsPdfIcon />}
                onClick={onSavePdf(filter)}
                sx={{ textTransform: "none" }}
              >
                Save as PDF
              </Button>
              <Button
                variant="contained"
                startIcon={<TableChartIcon />}
                onClick={onSaveExcel(filter)}
                sx={{ textTransform: "none" }}
              >
                Save as Excel
              </Button>
            </Box>
          </Box>

          {/* Table (children) */}
          {childrenArray[1] && (
            <Box>{React.cloneElement(childrenArray[1], { filter })}</Box>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

ComparisonTable.propTypes = {
  children: PropTypes.node.isRequired,
  onCopy: PropTypes.func.isRequired,
  onSavePdf: PropTypes.func.isRequired,
  onSaveExcel: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ComparisonTable;
