import axios from "axios";
import { API_URL } from "./config";
import {
  handleExportToWordBlobLOI,
  handleExportToWordBlobMRLOI,
} from "./loiDocxService";
import { saveAs } from "file-saver";

export const handleSaveLOIAsPDF = async (loiDetails) => {
  const wordDocBlob = await handleExportToWordBlobLOI(loiDetails);
  const uuid = crypto.randomUUID();
  const wordFile = new File([wordDocBlob], `${uuid}.docx`);
  const formData = new FormData();
  formData.append("file", wordFile);

  try {
    const response = await axios.post(`${API_URL}/export/word2pdf`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
      responseType: "blob",
    });

    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    saveAs(
      pdfBlob,
      `LOI_${loiDetails.owners}_${loiDetails.loiTemplate.type}.pdf`
    );
  } catch (error) {
    console.error("Error generating LOI:", error);
    throw error;
  }
};

export const handleSaveMRLOIAsPDF = async (details) => {
  const wordDocBlob = await handleExportToWordBlobMRLOI(details);
  const uuid = crypto.randomUUID();
  const wordFile = new File([wordDocBlob], `${uuid}.docx`);
  const formData = new FormData();
  formData.append("file", wordFile);

  try {
    const response = await axios.post(`${API_URL}/export/word2pdf`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
      responseType: "blob",
    });

    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    saveAs(pdfBlob, "MR_Amendment_LOI.pdf");
  } catch (error) {
    console.error("Error generating MR LOI:", error);
    throw error;
  }
};
